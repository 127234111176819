import React, {useEffect} from "react";
import {Helmet} from "react-helmet";
import Header from "partials/Header";
import Footer from "partials/Footer";

import Steps from "./Steps";
import FeatureRow from "partials/platform/FeatureRow";
import HeroPlatform from "../../../partials/platform/Hero";
import {
	CUSTOMIZATION_SECTION_TITLE_PROPS,
	OUTSOURCE_SECTION_TITLE_PROPS,
	CUSTOMIZATION_FEATS_PROPS,
	TOOL_FEATS_SECTION_TITLE_PROPS,
} from "./constants";
import CustomizationFeatContent from "./CustomizationFeatContent";
import ParagraphBlock from "../../../partials/paragraphBlock/ParagraphBlock";
import ContactUs from "../../../partials/contactus/ContactUs";
import TypeFormBtn from "../../../partials/typeFormBtn/TypeFormBtn";
import Industry from "../../../partials/mainPage/mainPageIndustry/Industry";
import ToolDemo from "../../../partials/toolDemo/ToolDemo";


type Props = {
	title: string;
}
const DataAnnotation: React.FC<Props> = ({title}) => {
	useEffect(() => {
		document.title = title;
	});

	return (
		<div className="flex flex-col min-h-screen overflow-hidden">
			<Helmet>
				<title>Linh - Data annotation services</title>
				<meta name="description" content="Linh AI provides image annotation services to annotate various data types: images, video, LIDAR, fusion sensor, text" />
			</Helmet>
			{/*  Site header */}
			<Header dark/>

			{/*  Page content */}
			<main className="flex-grow flex flex-col gap-y-20 items-center">
				{/*  Page illustration */}
				<HeroPlatform
					title="Superior Annotation Services for all Industries"
					supportText="Access the expertise of world-class annotators to drive the success of your AI with minimum efforts"
					invertColor className="platform-hero platform-service"
					supportTextWhiteColor
				/>
				<div className='flex flex-col gap-6 mt-4'>
					<ParagraphBlock
						headingLevel="h2"
						title={OUTSOURCE_SECTION_TITLE_PROPS.title}
						subtitle={OUTSOURCE_SECTION_TITLE_PROPS.subTitle}
						headline={OUTSOURCE_SECTION_TITLE_PROPS.sectName}
					/>
					<TypeFormBtn position='center' icon/>
				</div>
				<Steps/>
				<ParagraphBlock
					headingLevel="h2"
					title={CUSTOMIZATION_SECTION_TITLE_PROPS.title}
					subtitle={CUSTOMIZATION_SECTION_TITLE_PROPS.subTitle}
					headline={CUSTOMIZATION_SECTION_TITLE_PROPS.sectName}
				/>
				<div className="platform-box rounded-xl overflow-hidden section-width-control mx-auto p-10">
					<div className="flex flex-col gap-y-20 md:gap-y-10">
						{CUSTOMIZATION_FEATS_PROPS.map((customFeatProps) => {
							return <FeatureRow
								key={customFeatProps.imgUrl}
								{...customFeatProps}
								textContent={
									<CustomizationFeatContent
										{...customFeatProps.textContent}
									/>
								}
							/>
						})}
					</div>
				</div>
				<div className='section-width-control mx-auto'>
					<ToolDemo
						title={TOOL_FEATS_SECTION_TITLE_PROPS.title}
						subtitle={TOOL_FEATS_SECTION_TITLE_PROPS.subTitle}
						headline={TOOL_FEATS_SECTION_TITLE_PROPS.sectName}
					/>
				</div>
				<div className="section-width-control mx-auto">
					<Industry/>
				</div>
				<ContactUs/>
				{/*  Site footer */}
				<Footer/>
			</main>
		</div>
	);
}

export default DataAnnotation;

