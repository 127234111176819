import Card from "./Card";
import './style.scss'
import ParagraphBlock from "../paragraphBlock/ParagraphBlock";

const comments = [
	{
		name: 'Louis Phung',
		logo: 'https://assets-global.website-files.com/5d7b77b063a9066d83e1209c/61ddc2321dc66a7f984529ec_cd33bb9b-40f7-4185-b08e-5cf69054f721-removebg-preview.png',
		avatar: 'https://assets-global.website-files.com/5d7b77b063a9066d83e1209c/620edfd0767ac9266cca25c1_Raman.jpeg',
		role: 'Handsome guy, Linh AI',
		description: '"The API is very straightforward to use, ' +
			'so we can easily get data into our local computer system. ' +
			'Annotations are formatted in JSON, which is easier to parse. ' +
			'Linh AI also offers a lot of pre-trained networks that we can utilize."',
	},
	{
		name: 'Louis Phung',
		logo: 'https://assets-global.website-files.com/5d7b77b063a9066d83e1209c/61ddc2321dc66a7f984529ec_cd33bb9b-40f7-4185-b08e-5cf69054f721-removebg-preview.png',
		avatar: 'https://assets-global.website-files.com/5d7b77b063a9066d83e1209c/620edfd0767ac9266cca25c1_Raman.jpeg',
		role: 'Handsome guy, Linh AI',
		description: '"The API is very straightforward to use, ' +
			'so we can easily get data into our local computer system. ' +
			'Annotations are formatted in JSON, which is easier to parse. ' +
			'Linh AI also offers a lot of pre-trained networks that we can utilize."',
	},
	{
		name: 'Louis Phung',
		logo: 'https://assets-global.website-files.com/5d7b77b063a9066d83e1209c/61ddc2321dc66a7f984529ec_cd33bb9b-40f7-4185-b08e-5cf69054f721-removebg-preview.png',
		avatar: 'https://assets-global.website-files.com/5d7b77b063a9066d83e1209c/620edfd0767ac9266cca25c1_Raman.jpeg',
		role: 'Handsome guy, Linh AI',
		description: '"The API is very straightforward to use, ' +
			'so we can easily get data into our local computer system. ' +
			'Annotations are formatted in JSON, which is easier to parse. ' +
			'Linh AI also offers a lot of pre-trained networks that we can utilize."',
	},
	{
		name: 'Louis Phung',
		logo: 'https://assets-global.website-files.com/5d7b77b063a9066d83e1209c/61ddc2321dc66a7f984529ec_cd33bb9b-40f7-4185-b08e-5cf69054f721-removebg-preview.png',
		avatar: 'https://assets-global.website-files.com/5d7b77b063a9066d83e1209c/620edfd0767ac9266cca25c1_Raman.jpeg',
		role: 'Handsome guy, Linh AI',
		description: '"The API is very straightforward to use, ' +
			'so we can easily get data into our local computer system. ' +
			'Annotations are formatted in JSON, which is easier to parse. ' +
			'Linh AI also offers a lot of pre-trained networks that we can utilize."',
	},
	{
		name: 'Louis Phung',
		logo: 'https://assets-global.website-files.com/5d7b77b063a9066d83e1209c/61ddc2321dc66a7f984529ec_cd33bb9b-40f7-4185-b08e-5cf69054f721-removebg-preview.png',
		avatar: 'https://assets-global.website-files.com/5d7b77b063a9066d83e1209c/620edfd0767ac9266cca25c1_Raman.jpeg',
		role: 'Handsome guy, Linh AI',
		description: '"The API is very straightforward to use, ' +
			'so we can easily get data into our local computer system. ' +
			'Annotations are formatted in JSON, which is easier to parse. ' +
			'Linh AI also offers a lot of pre-trained networks that we can utilize."',
	},
	{
		name: 'Louis Phung',
		logo: 'https://assets-global.website-files.com/5d7b77b063a9066d83e1209c/61ddc2321dc66a7f984529ec_cd33bb9b-40f7-4185-b08e-5cf69054f721-removebg-preview.png',
		avatar: 'https://assets-global.website-files.com/5d7b77b063a9066d83e1209c/620edfd0767ac9266cca25c1_Raman.jpeg',
		role: 'Handsome guy, Linh AI',
		description: '"The API is very straightforward to use, ' +
			'so we can easily get data into our local computer system. ' +
			'Annotations are formatted in JSON, which is easier to parse. ' +
			'Linh AI also offers a lot of pre-trained networks that we can utilize."',
	},
	{
		name: 'Louis Phung',
		logo: 'https://assets-global.website-files.com/5d7b77b063a9066d83e1209c/61ddc2321dc66a7f984529ec_cd33bb9b-40f7-4185-b08e-5cf69054f721-removebg-preview.png',
		avatar: 'https://assets-global.website-files.com/5d7b77b063a9066d83e1209c/620edfd0767ac9266cca25c1_Raman.jpeg',
		role: 'Handsome guy, Linh AI',
		description: '"The API is very straightforward to use, ' +
			'so we can easily get data into our local computer system. ' +
			'Annotations are formatted in JSON, which is easier to parse. ' +
			'Linh AI also offers a lot of pre-trained networks that we can utilize."',
	},
	{
		name: 'Louis Phung',
		logo: 'https://assets-global.website-files.com/5d7b77b063a9066d83e1209c/61ddc2321dc66a7f984529ec_cd33bb9b-40f7-4185-b08e-5cf69054f721-removebg-preview.png',
		avatar: 'https://assets-global.website-files.com/5d7b77b063a9066d83e1209c/620edfd0767ac9266cca25c1_Raman.jpeg',
		role: 'Handsome guy, Linh AI',
		description: '"The API is very straightforward to use, ' +
			'so we can easily get data into our local computer system. ' +
			'Annotations are formatted in JSON, which is easier to parse. ' +
			'Linh AI also offers a lot of pre-trained networks that we can utilize."',
	},
]

export default function Comments() {
	const renderComments = () => {
		const result: JSX.Element[] = [];
		comments.forEach((_c) => {
			result.push(<Card {..._c} />)
		})

		return result;
	}

	return (
		<div></div>
		// <div className='thought-wrapper'>
		// 	<ParagraphBlock title="Our clients' thoughts" headingLevel="h2" headline='Trusted by organizations'/>
		// 	<div className='flex flex-row flex-nowrap animate-scroll comment-row-wrapper'>
		// 		{renderComments()}
		// 	</div>
		// </div>
	);
}
