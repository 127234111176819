import React, {useEffect} from "react";

import Header from "partials/Header";
import Footer from "partials/Footer";

import HeroPlatform from "partials/platform/Hero";
import {FeatureBoxesRow} from "partials/platform/FeatureBox";
import Card from "../../partials/platform/Card";
import ContactUs from "../../partials/contactus/ContactUs";


type Props = {
	title: string;
}
const DataAnnotation: React.FC<Props> = ({title}) => {
	useEffect(() => {
		document.title = title;
	});

	return (
		<div className="flex flex-col min-h-screen overflow-hidden">
			{/*  Site header */}
			<Header dark/>

			{/*  Page content */}
			<main className="flex-grow">
				{/*  Page illustration */}
				<HeroPlatform
					title="Dataset management"
					supportText="Level up your AI model by harnessing the most value from your data"
					invertColor className="platform-hero platform-dataset"
					additionalContent={<img className='platform-add-on-img' src={require('../../images/dataset-platform.png').default}/>}
				/>
				<div className="flex flex-col gap-y-10 lg:gap-y-15 platform-main p-4 sm:p-10 md:p-20">
					<div className="flex flex-col gap-y-10 text-center">
						<div className="h2 text-4xl">
							Standardise the way you structure your assets.
						</div>
						<div className="flex flex-row flex-wrap gap-2 lg:gap-10 justify-center">
							<Card title='Image Dataset' img={require('../../images/2d-platform-dataset.png').default} />
							<Card title='Point cloud Dataset' img={require('../../images/3d-platform-dataset.png').default} />
							<Card title='Video Dataset' img={require('../../images/video-platform-dataset.png').default} />
						</div>
					</div>
					<FeatureBoxesRow
						data={[
							{
								title: "Seamless integration",
								content: "Integrate with cloud providers (AWS, Azure, GCP) to stream the data from their cloud buckets without having to manually upload it.",
								imgUrl: require('../../images/integration.png').default
							},
							{
								title: "Support various data format",
								content: "Supports both 2D and 3D data types: .jpg, .png, .mp4, .pcd, .mov and many more.",
								imgUrl: require('../../images/any-format.png').default
							},
						]}
					/>
					<FeatureBoxesRow
						data={[
							{
								title: "Data Curation",
								content: "Apply active learning and deduplication tools to select the most valuable data points to annotate.",
								imgUrl: require('../../images/data-operation.png').default
							},
							{
								title: "Data Exploration",
								content: "Search for the right data with our powerful filters: created date, annotation progress, name.",
								imgUrl: require('../../images/exploration.png').default
							},
						]}
					/>
					<FeatureBoxesRow
						data={[
							{
								title: "Interactive statistics",
								content: "Gain insights into your dataset’s composition. Understand the class distribution and correlation between classes to fine-tune your dataset.",
								imgUrl: require('../../images/interactive-insights.png').default
							},
							{
								title: "Dataset versioning",
								content: "Version your dataset at every iteration to reproduce your experiment result.",
								imgUrl: require('../../images/version-control.png').default
							},
						]}
					/>
					<ContactUs title='Talk to our expert and schedule a demo'/>
				</div>
				{/*  Site footer */}
				<Footer/>
			</main>
		</div>
	);
}

export default DataAnnotation;

