import IndustryCard, { IndustryCardInterface } from "./IndustryCard";

export interface MultipleIndustryCardProps {
  industries: IndustryCardInterface[];
}

export default function MultipleIndustryCards(props: MultipleIndustryCardProps) {
  const { industries } = props;

  const renderMultipleIndustries = () => {
    const result: JSX.Element[] = [];
    industries.forEach((_i) => {
      result.push(<IndustryCard {..._i} />);
    });

    return result;
  };

  return (
    <div className="flex flex-row flex-wrap justify-center" style={{ gap: 8, marginTop: 32 }}>
      {renderMultipleIndustries()}
    </div>
  );
}
