import {
	AiOutlineFundView,
	BiBrain,
	BiCategoryAlt,
	BiData,
	BiGitBranch,
	IoStatsChartOutline,
	MdAutoFixNormal,
	MdManageSearch,
	MdOutlineCompare,
	MdOutlineImageSearch,
	MdOutlineInsights,
	MdOutlineSecurity,
	MdOutlineSupervisedUserCircle,
	MdPeopleOutline,
	RiCheckboxMultipleBlankLine,
	VscServerProcess
} from "react-icons/all";
import Function, {Position} from "./Function";

const datasetOptions = [
	{
		title: 'Any Data Format',
		icon: <RiCheckboxMultipleBlankLine fontSize={16} color='white'/>,
		contentTitle: 'Support any formats you need',
		contentSubTitle: 'One platform for uploading, browsing, and annotating images, videos, and point clouds. Graviton supports a variety of standard annotation formats, as well as your own custom annotation formats.',
		demoImg: require('../../../images/any-format.png').default
	},
	{
		title: 'Data Operations',
		icon: <BiData fontSize={16} color='white'/>,
		contentTitle: 'Advanced Data Operations',
		contentSubTitle: 'Curate or transform your datasets with our advanced data operations such as anonymization, deduplication and active learning. Help reduce data processing costs significantly.',
		demoImg: require('../../../images/data-operation.png').default,
	},
	{
		title: 'Version Control',
		icon: <BiGitBranch fontSize={16} color='white'/>,
		contentTitle: 'Version control datasets',
		contentSubTitle: 'Keep track of how the annotations change. Manage your growing datasets by creating dataset versions.',
		demoImg: require('../../../images/version-control.png').default
	},
	{
		title: 'Data Exploration',
		icon: <MdManageSearch fontSize={16} color='white'/>,
		contentTitle: 'Visualize, Sort and Filter',
		contentSubTitle: 'Instantly visualize your data and annotations. Sort and filter by upload time, annotator, label, image metadata and attributes.',
		demoImg: require('../../../images/exploration.png').default
	},
]

const annotationOptions = [
	{
		title: 'Semi-automatic Annotation',
		icon: <MdAutoFixNormal fontSize={16} color='white'/>,
		contentTitle: 'A single click for pixel-perfect polygons',
		contentSubTitle: 'Using our semi-automatic annotation tool, create pixel-perfect polygons simply by clicking on the object parts.',
		demoImg: require('../../../images/semi-automatic.png').default
	},
	{
		title: 'Annotation Type',
		icon: <BiCategoryAlt fontSize={16} color='white'/>,
		contentTitle: 'Support various annotation types',
		contentSubTitle: 'Annotate Video, Image, Point Cloud and Text data with faster data throughput. ' +
			'Solve a wide range of tasks such as instance segmentation, object tracking and more.',
		demoImg: require('../../../images/various-tools.png').default
	},
	{
		title: 'Performance Statistics',
		icon: <IoStatsChartOutline fontSize={16} color='white'/>,
		contentTitle: 'Gain insights with statistics',
		contentSubTitle: 'Actionable analytics for team performance that show the efficiency of your tasks and annotators.',
		demoImg: require('../../../images/performance-insights.png').default
	},
	{
		title: 'Quality Assurance',
		icon: <VscServerProcess fontSize={16} color='white'/>,
		contentTitle: 'Designed for perfect quality',
		contentSubTitle: 'Build the highest quality training data extremely fast. We provide comprehensive multi-layer quality assurance processes and effective collaboration tools to drive successful projects and boost model performance.',
		demoImg: require('../../../images/QA.png').default
	},
]

const modelEvalOptions = [
	{
		title: 'Inference visualization',
		icon: <AiOutlineFundView fontSize={16} color='white'/>,
		contentTitle: 'Explore prediction outputs from your models',
		contentSubTitle: 'Analyze your model performance by visualizing your inferences and ground truths.',
		demoImg: require('../../../images/model-eval.png').default
	},
	{
		title: 'Compare model performance',
		icon: <MdOutlineCompare fontSize={16} color='white'/>,
		contentTitle: 'Evaluate model performance using various metrics',
		contentSubTitle: 'Evaluate the model by comparing its predictions against labeled ground truths or against other predictions. Use various quantitative metrics such as IoU, F1 score.',
		demoImg: require('../../../images/model-cmp.png').default
	},
	{
		title: 'Interactive insights',
		icon: <MdOutlineInsights fontSize={16} color='white'/>,
		contentTitle: 'Uncover what’s holding your model back',
		contentSubTitle: 'Use confusion matrix to identify the most critical patterns of failures and prioritize the work that improves your model most.',
		demoImg: require('../../../images/model-insights.png').default
	},
	{
		title: 'Edge case mining',
		icon: <MdOutlineImageSearch fontSize={16} color='white'/>,
		contentTitle: 'Collect relevant data to label',
		contentSubTitle: 'Build new scenarios based on meta data and annotation. Find data points that belongs to these scenarios from existing unlabelled database with only two clicks.',
		demoImg: require('../../../images/edge-case.png').default
	},
]

const annotationServiceOptions = [
	{
		title: 'Professional Annotators',
		icon: <MdPeopleOutline fontSize={16} color='white'/>,
		contentTitle: 'Professional on-demand annotators',
		contentSubTitle: 'Graviton gives you access to highly skilled annotators who know the platform from top to bottom.',
		demoImg: require('../../../images/annotators.png').default
	},
	{
		title: 'Domain Experts',
		icon: <BiBrain fontSize={16} color='white'/>,
		contentTitle: 'Domain expert annotators',
		contentSubTitle: 'Ensure data accuracy by collaborating with experts from different industries.',
		demoImg: require('../../../images/domain-expert.png').default
	},
	{
		title: 'Project Managers',
		icon: <MdOutlineSupervisedUserCircle fontSize={16} color='white'/>,
		contentTitle: 'Fully managed annotation tasks',
		contentSubTitle: 'Collaborate with a dedicated Linh AI project manager. The manager will ensure all annotation deliverables meet your expectations.',
		demoImg: require('../../../images/project-managers.png').default
	},
	{
		title: 'Security Standards',
		icon: <MdOutlineSecurity fontSize={16} color='white'/>,
		contentTitle: 'Security Standards',
		contentSubTitle: 'We care about clients’ data privacy. Our platform is SOC2 compliant, HIPAA compliant and ISO 27001 compliant.',
		demoImg: require('../../../images/compliance.png').default
	},
]

const functionContent = [
	{
		title: 'Manage Your Datasets',
		subTitle: 'Help machine learning teams manage their most valuable assets.',
		options: datasetOptions,
		position: 'start',
		actionUrl: '/platform/data-management',
		actionBtn: 'Explore Dataset Management'
	},
	{
		title: 'Annotate Various Types Of Data',
		subTitle: 'Create high-quality annotations with our annotation tools. Boost your annotation speed by up to 6x.',
		options: annotationOptions,
		position: 'end',
		actionUrl: '/platform/data-annotation',
		actionBtn: 'Explore Annotation Toolset'
	},
	{
		title: 'Model Evaluation',
		subTitle: 'Automatic, visual and qualitative way of interpreting and comparing behaviours of ML models.',
		options: modelEvalOptions,
		position: 'start',
		actionUrl: '/platform/model-evaluation',
		actionBtn: 'Explore Model Evaluation'
	},
	{
		title: 'Annotation Services',
		subTitle: 'Automate your annotation workflows. Ensure quality and speed with our experienced annotators and comprehensive quality control processes.',
		options: annotationServiceOptions,
		position: 'end',
		actionUrl: '/platform/annotation-services',
		actionBtn: 'Explore Annotation Service'
	},
]

export default function MainPageFunctions() {
	const renderFunctions = () => {
		const result: JSX.Element[] = [];
		functionContent.forEach((_f) => {
			result.push(
				<Function
					title={_f.title}
					subTitle={_f.subTitle}
					options={_f.options}
					actionBtn={_f.actionBtn}
					actionUrl={_f.actionUrl}
					position={_f.position as Position}
				/>
			)
		})

		return result;
	}

	return (
		<div>
			{renderFunctions()}
		</div>
	)
}
