import React from 'react';
import {FEATURE_NAME} from './constants';

type FeatContentProps = {
	textContent: React.ReactNode;
	demoUrl: string;
	display?: boolean;
}

export const FEATURE_CONTENTS_OBJ: Record<FEATURE_NAME, FeatContentProps> = {
	[FEATURE_NAME.INTERACTIVE_SEG]: {
		textContent: (
			<div className='flex flex-col gap-2'>
				<h2 className="h3">Interactive segmentation</h2>
				<h3>Fast and efficient object segmentation with just a few clicks. Left-clicked includes object parts
					and
					right-clicked excludes the background.</h3>
			</div>
		),
		demoUrl: require('../../../images/interactive-seg.gif').default
	},
	[FEATURE_NAME.POLYGON]: {
		textContent: (
			<div className='flex flex-col gap-2'>
				<h2 className="h3">Polygon</h2>
				<h3>Find contour of an object or define an amorphous entity such as “road”, “sky”. Used for semantic
					segmentation and instance segmentation.</h3>
			</div>
		),
		demoUrl: require('../../../images/polygon.png').default
	},
	[FEATURE_NAME.BOUNDING_BOX]: {
		textContent: (
			<div className='flex flex-col gap-2'>
				<h2 className="h3">Bounding box</h2>
				<h3>Draw a rectangle box to fit the object’s boundary. Use for detector task.</h3>
			</div>
		),
		demoUrl: require('../../../images/bounding-box.png').default
	},
	[FEATURE_NAME.KEY_POINT]: {
		textContent: (
			<div className='flex flex-col gap-2'>
				<h2 className="h3">Keypoint</h2>
				<h3>Define a combination of points to form a point map representing the pose of an object. Primarily
					used for face, landmark annotation.</h3>
			</div>
		),
		demoUrl: require('../../../images/point.png').default
	},

	[FEATURE_NAME.POLYLINE]: {
		textContent: (
			<div className='flex flex-col gap-2'>
				<h2 className="h3">Polyline</h2>
				<h3>Form a line with a series of points to describe lane markings or trajectories.</h3>
			</div>
		),
		demoUrl: require('../../../images/laneline.png').default
	},
	[FEATURE_NAME.CUBOID]: {
		textContent: (
			<div className='flex flex-col gap-2'>
				<h2 className="h3">Cuboid</h2>
				<h3>Enclose an object with a three-dimentional box that defines its dimension and pose on 2D images.
					Suitable for 3D cuboid estimation, object detection and 6DoF pose estimation.</h3>
			</div>
		),
		demoUrl: require('../../../images/cuboid.png').default
	},
	[FEATURE_NAME.IMAGE_TAG]: {
		textContent: (
			<div className='flex flex-col gap-2'>
				<h2 className="h3">Image tag</h2>
				<h3>Describe a whole image based on visible features for classification purposes. Good for objects
					covering more than 50% of the image.</h3>
			</div>
		),
		demoUrl: require('../../../images/classification.png').default
	},
	[FEATURE_NAME.ATTRIBUTES]: {
		textContent: (
			<div className='flex flex-col gap-2'>
				<h2 className="h3">Attributes</h2>
				<h3>Providing additional metadata for an annotation. Attributes classify objects after detecting
					them.</h3>
			</div>
		),
		demoUrl: require('../../../images/metadata-annotation.png').default
	},
	[FEATURE_NAME.CUBOID_3D]: {
		textContent: (
			<div className='flex flex-col gap-2'>
				<h2 className="h3">3D Cuboid Point Cloud</h2>
				<h3>Defining object boundary with 3D cuboid in point cloud space. Applicable to LiDAR sensor data and
					Fusion sensor data.</h3>
			</div>
		),
		demoUrl: require('../../../images/point-cloud.png').default
	},
	[FEATURE_NAME.TRACK_ID]: {
		textContent: (
			<div className='flex flex-col gap-5'>
				<div className='flex flex-col gap-2'>
					<h2 className="h3">Object tracking</h2>
					<h3>Mark an object with a specific ID across frames to track its change in position and pose.
						Suitable for video object tracking and point cloud object tracking.</h3>
				</div>
			</div>
		),
		demoUrl: require('../../../images/tracking.png').default
	},
}

const FeatureContent: React.FC<FeatContentProps> =
	({
		 textContent,
		 demoUrl,
		display,
	 }) => {
		return (
			<div className="flex flex-col md:flex-row items-center gap-5 platform-box p-5 md:p-10" style={{display: display ? 'flex' : 'none'}}>
				<div className='text-center md:text-left flex-1'>
					{textContent}
				</div>
				<div className="platform-tool-img-wrapper flex-1">
					<img
						alt="We provide Image Annotation services to a wide range of industries that leverage machine learning and AI to run their businesses"
						data-aos="fade-up"
						data-aos-delay="400"
						src={demoUrl}
						className='platform-tool-img-content'
					/>
				</div>
			</div>
		)
	}

export default FeatureContent;
