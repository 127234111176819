import {
	AiOutlineNodeIndex, AiOutlineTag, BiCube,
	BsBoundingBoxCircles,
	FaRegDotCircle, GrMultiple,
	GrSelect, GrThreeD,
	TbNote, TbPolygon
} from "react-icons/all";

export enum FEATURE_NAME {
	INTERACTIVE_SEG = 'interactiveSeg',
	POLYGON = 'polygon',
	BOUNDING_BOX = 'boundingBox',
	KEY_POINT = 'keyPoint',
	POLYLINE = 'line',
	CUBOID = 'cuboid',
	IMAGE_TAG = 'imageTag',
	ATTRIBUTES = 'attributes',
	CUBOID_3D = '3dCuboidPointCloud',
	TRACK_ID = 'instancesTrackingIds',
}

const fontSize = 24;
export const FEATURE_BUTTON_ICONS: Record<FEATURE_NAME, any> = {
	[FEATURE_NAME.INTERACTIVE_SEG]: <GrSelect fontSize={fontSize} className='platform-tool-icon'/>,
	[FEATURE_NAME.POLYGON]: <TbPolygon fontSize={fontSize} className='platform-tool-icon'/>,
	[FEATURE_NAME.BOUNDING_BOX]: <BsBoundingBoxCircles fontSize={fontSize} className='platform-tool-icon'/>,
	[FEATURE_NAME.KEY_POINT]: <FaRegDotCircle fontSize={fontSize} className='platform-tool-icon'/>,

	[FEATURE_NAME.POLYLINE]: <AiOutlineNodeIndex fontSize={fontSize} className='platform-tool-icon'/>,
	[FEATURE_NAME.CUBOID]: <BiCube fontSize={fontSize} className='platform-tool-icon'/>,
	[FEATURE_NAME.IMAGE_TAG]: <AiOutlineTag fontSize={fontSize} className='platform-tool-icon'/>,
	[FEATURE_NAME.ATTRIBUTES]: <TbNote fontSize={fontSize} className='platform-tool-icon'/>,
	[FEATURE_NAME.CUBOID_3D]: <GrThreeD fontSize={fontSize} className='platform-tool-icon'/>,
	[FEATURE_NAME.TRACK_ID]: <GrMultiple fontSize={fontSize} className='platform-tool-icon'/>,
}
