import './style.scss';
import ToolChip from "../../toolChip/ToolChip";

export interface FunctionHeaderOption {
	icon: any;
	title: string;
}

export interface FunctionHeaderInterface {
	title: string;
	subtitle: string;
	options: FunctionHeaderOption[],
	selected?: number,
	onSelect: (id: number) => void;
}

export default function FunctionHeader(props: FunctionHeaderInterface) {
	const {title, subtitle, options, onSelect, selected} = props;
	const onClick = (id: number) => {
		onSelect(id);
	}

	const renderOptions = () => {
		const result: JSX.Element[] = [];
		options.forEach((_o, index) => {
			result.push(
				<ToolChip
					onClick={onClick}
					id={index}
					title={_o.title}
					active={selected === index}
					size='md'
					icon={_o.icon}
				/>)
		})

		return result;
	}

	return (
		<div className='flex flex-col header-block '>
			<h2 className={`font-medium text-4xl content-resize`}>{title}</h2>
			<h3 className={`text-base content-resize`}>
				{subtitle}
			</h3>
			<div className={`flex flex-row justify-center func-btn-controller overflow-scroll btw-row `}>
				{renderOptions()}
			</div>
		</div>
	)
}
