import React, { useEffect } from 'react'
import Header from '../../partials/Header'
import Footer from '../../partials/Footer'
import Mission from './Mission'
import CompanyBackground from './CompanyBackground'
import TeamStructure from './TeamStructure'

const AboutUs = ({ title }: any) => {
  useEffect(() => {
    document.title = title.toString()
  })

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <Header dark={false} />

      {/*  Page sections */}
      <main className="flex-grow">
        <Mission/>
        {/*<TeamStructure />*/}
        <CompanyBackground />
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  )
}

export default AboutUs
