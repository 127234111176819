import "./style.scss";

export interface ParagraphBlockInterface {
  headline?: string,
  title: string,
  headingLevel: "h1" | "h2";
  subtitle?: string
}

export default function ParagraphBlock(props: ParagraphBlockInterface) {
  const { headline, subtitle, title, headingLevel } = props;

  return (
    <div>
      <div className="max-w-3xl mx-auto">
        {headline && (
          <h3 className="text-center font-semibold text-sm text-blue-500 mx-4"
             style={{ textTransform: "uppercase" }}>
            {headline}
          </h3>)
        }
      </div>
      <div className="max-w-5xl mx-auto">
        <h1 className={`text-center text-4xl font-medium mx-4 title-size---${headingLevel}`}>{title}</h1>
      </div>
      <div className="max-w-4xl mx-auto">
        {subtitle && (
          <h2 className="text-center mx-4 text-base subtitle-size text-gray-500">
            {subtitle}
          </h2>)
        }
      </div>
    </div>
  );
}
