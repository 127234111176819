import "./style.scss";

export interface IndustryCardInterface {
  title: string;
  icon?: any;
  imgSrc: string;
  linkUrl: string;
  preventClick?: boolean;
}

export default function IndustryCard(props: IndustryCardInterface) {
  const { title, icon, imgSrc, linkUrl, preventClick} = props;
  const onClick = (e: any) => {
      if (preventClick) {
          e.preventDefault();
      }
  }

  return (
    <a href={linkUrl} onClick={onClick} className="flex flex-col industry-card-block">
      <img src={imgSrc}
           loading="lazy"
           className="industry-img-style"
           alt={`${title}`} />
      <div className="flex flex-row industry-title">
        {icon && icon}
        <p className='text-center'>{title}</p>
      </div>
    </a>
  );
}
