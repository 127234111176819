import ParagraphBlock from "../../paragraphBlock/ParagraphBlock";
import "./style.scss";
import GetEmail from "../../button/GetEmail";

export interface MainPageBannerProps {
  headline?: string,
  title: string,
  subtitle: string,
  imgSrcs: string[],
}

export default function MainPageBanner(props: MainPageBannerProps) {
  const { headline, title, subtitle, imgSrcs } = props;

  return (
    <div>
      <ParagraphBlock
        headline={headline}
        title={title}
        headingLevel="h1"
        subtitle={subtitle}
      />
      <div className="homepage---hero-form">
        <GetEmail />
      </div>
      <div className="bottom-imgs">
        <img alt="annotate & label data for machine learning" data-aos="fade-right" className="bottom-img bottom-left" src={imgSrcs[0]} />
        <img alt="develop AI training data for computer vision systems" data-aos="fade-right" className="bottom-img bottom-middle" src={imgSrcs[1]} />
        <img alt="low-cost annotation service for companies" data-aos="fade-right" className="bottom-img bottom-right" src={imgSrcs[2]} />
      </div>
    </div>
  );
}
