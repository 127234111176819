/**
 * This component lay out is like this
 *  [image] [content] or [content] [image] and
 *   [image]
 *   [content] if the page width is small
 */

export type Props = {
	imgUrl: string;
	textContent: React.ReactNode;
	isImgLeft: boolean;
}

const FeatureRow: React.FC<Props> = ({imgUrl, textContent, isImgLeft}) => {
	return (
		<div
			className={`flex gap-y-10 md:gap-x-10 items-center ${isImgLeft ? 'row-items-reverse-position' : 'row-items-position'}`}>
			<div className='w-full md:w-1/2'>
				{textContent}
			</div>
			<img src={imgUrl} className="mx-auto w-full md:w-1/2"/>
		</div>
	)
}

export default FeatureRow;
