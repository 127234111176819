export type Position = 'start' | 'end';

export interface FunctionContentInterface {
	contentTitle: string;
	contentSubTitle: string;
	demoImg: string;
	position?: Position;
	display?: boolean;
}

export default function FunctionContent(props: FunctionContentInterface) {
	const {contentSubTitle, contentTitle, demoImg, position, display} = props;

	return (
		<div className='flex justify-between items-center content-controller' style={{flexDirection: position === 'end' ? 'row-reverse' : 'row', gap: 16, display: display ? 'flex' : 'none'}}>
			<div className='flex flex-col content-txt-block'>
				<h3 className='font-medium text-4xl'>{contentTitle}</h3>
				<h4>{contentSubTitle}</h4>
			</div>
			<div className='img-function-wrapper'>
				<img
					className='content-img'
					src={demoImg}
				/>
			</div>
		</div>
	)
}
