import React from 'react'
import {PopupButton} from "@typeform/embed-react";

function HeroProcuct() {
  return (
    <section className="relative bg-gradient-to-r from-blue-600 via-white-600 to-teal-400">
      <div className="mx-auto px-4 sm:px-6 relative">
        {/* Hero content */}
        <div className="relative pt-32 pb-12 md:pt-30 md:pb-20 ">
          {/* Content */}
          <div className="max-w-5xl mx-auto text-center pb-12 md:pb-16">
            <h1
              className="h2 mt-5 mb-6 text-center text-white"
              data-aos="fade-up"
            >
              Better Processes - Better Data - Better Models
            </h1>
            <p
              className="text-xl text-white text-center max-w-5xl mb-8 mx-auto"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              Graviton is the tool for evidence-based ML development. <br />
              Manage, curate, annotate data and evaluate training results on a
              single platform.
            </p>
            <PopupButton id="ezU0KeBU" className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center md:justify-center space-y-4 sm:space-y-0 sm:space-x-4">
              <button
                data-tf-popup="ezU0KeBU"
                data-tf-size="70"
                data-tf-iframe-props="title=Early Access"
                data-tf-medium="snippet"
                data-tf-hidden="utm_source=xxxxx,utm_medium=xxxxx,utm_content=xxxxx"
                className="btn text-white bg-transparent hover:bg-white hover:text-black border border-white group"
                data-aos="fade-up"
              >
                <span>Early Access</span>
                <span className="tracking-normal text-white group-hover:text-black transform group-hover:translate-x-1 ml-1 ease-out duration-300">
                  -&gt;
                </span>
              </button>
            </PopupButton>
          </div>
          {/* Image */}
          {/* <img
            className=""
            src={require("../../images/hero-image-02.png").default}
            width="500"
            alt="Hero"
            data-aos="fade-up"
            data-aos-delay="400"
          /> */}
        </div>
      </div>
    </section>
  )
}

export default HeroProcuct
