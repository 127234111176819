import React from 'react'
import {PopupButton} from "@typeform/embed-react";

function EarlyAccessButton() {
  return (
      <PopupButton id="ezU0KeBU">
        <button
            data-tf-popup="ezU0KeBU"
            data-tf-size="70"
            data-tf-iframe-props="title=Early Access"
            data-tf-medium="snippet"
            className="btn text-white bg-black hover:bg-gray-800 w-full mt-6 mb-4 sm:w-auto sm:mb-0 font-light"
        >
          Early Access
        </button>
      </PopupButton>
  )
}

export default EarlyAccessButton
