import React from 'react';
import './style.scss';
import TeamMosaic from '../../images/team-mosaic-01.png';
import Vision from '../../images/vision.png';

const Mission = () => {
  return (
    <section className='page-section'>
      <div className="container mx-auto section-size-control flex flex-col gap-20">
        <div className='flex flex-col items-center gap-10 justify-center md:flex-row' data-aos='fade-up'>
          <div className="text-center md:text-left flex-1">
            <h2 className="h2 mb-2">Our Mission</h2>
            <p className="text-xl text-gray-600">
              We are empowering everyone to create smarter, faster, and better AI.
            </p>
          </div>
          <div className='flex-1 mission-img-wrapper'>
            <img
                className='mission-img'
                src={TeamMosaic}/>
          </div>
        </div>

        <div className='flex items-center gap-10 justify-center vision-section' data-aos='fade-up'>
          <div className="text-center md:text-left flex-1">
            <h2 className="h2 mb-2">Our Vision</h2>
            <p className="text-xl text-gray-600">
              We want to see meaningful AI applications around us empowered by
              Linh AI.
            </p>
          </div>
          <div className='flex-1 mission-img-wrapper'>
            <img
                className='mission-img'
                src={Vision}/>
          </div>
        </div>

        <div className="text-center md:text-left flex-1" data-aos='fade-up'>
          <h2 className="h2 mb-2 text-center">The Name</h2>
          <p className="text-xl text-gray-600">
            <b>Linh</b> is a Vietnamese name that means "soul" and “spirit”, a
            rendition of the Chinese character <strong>靈</strong> (líng). The
            name <b>Linh AI</b> is a reminder for everyone in our team to keep
            empowering meaningful AI applications - giving AI a “good soul”.
          </p>
        </div>
      </div>
    </section>
  )
}

export default Mission;
