import type { StepContent, SectionTitleProps } from "./types";
import type { Props as FeatureRowProps } from "partials/platform/FeatureRow";
import type { Props as CustomizationFeatContentProps} from "./CustomizationFeatContent";
import type { AnnotationButtonContent } from "./AnnotationFeatContent";
import type { IndustryItemContent } from "./types";
import IntegrationImg from '../../../images/integration.png';
import OutsourceImg from '../../../images/outsource-service.png';
import RequirementImg from '../../../images/requirement-analysis.png';
import CalibrationImg from '../../../images/calibration.png';
import ProductionImg from '../../../images/production.png';
import QAImg from '../../../images/QA.png';
import InteractiveImg from '../../../images/interactive-seg.gif';
import DynamicAllocationImg from '../../../images/dynamic-allocation.png';

export const LABEL_SECTION_TITLE_PROPS: SectionTitleProps = {
  sectName: "Labelling services",
  title: "Superior Annotation Services for all Industries",
  subTitle: "Access the expertise of world-class annotators to drive the success of your AI with minimum efforts",
  buttonLink: "./",
  buttonText: "Get a quote →"
}

export const OUTSOURCE_SECTION_TITLE_PROPS: SectionTitleProps = {
  sectName: "OUTSOURCE",
  title: "Generate high-quality data at fast speed",
  subTitle: "Contact us today and we will take care of your model inputs",
  buttonLink: "./",
  buttonText: "Get a quote →"
}

export const PROGRESS_CHANGE_PERIOD = 5;
export const STEP_CHANGE_PERIOD = 7500;

export const STEPS_CONTENT: StepContent[] = [
  {
    stepName: "Create dataset",
    content: "Add a new dataset on our platform. We support both local upload and data transfer from cloud provider.",
    imgUrl: IntegrationImg,
  },
  {
    stepName: "Request outsource service",
    content: "Request outsource annotation for your dataset. We will allocate a dedicated project manager to initiate the project.",
    imgUrl: OutsourceImg,
  },
  {
    stepName: "Requirement analysis",
    content: "Upload annotation requirements according to our standard template. Project manager analyses the requirements and clarify with you any doubts.",
    imgUrl: RequirementImg,
  },
  {
    stepName: "Pilot batch",
    content: "Enjoy up to $30 of free pilot data to verify your annotation requirements and set quality expectation for our annotators. Our professional team will deliver the pilot annotations within a few hours after you upload the data.",
    imgUrl: CalibrationImg,
  },
  {
    stepName: "Production batch",
    content: "Scale the volume of annotation data points. Our professional annotation team will apply experiences and best practices from pilot batch to produce the best quality data.",
    imgUrl: ProductionImg,
  }
]

export const CUSTOMIZATION_SECTION_TITLE_PROPS: SectionTitleProps = {
  sectName: "Annotation workflow customization",
  title: "Design your annotation workflow to meet your need",
  subTitle: "Graviton’s workflow supports multi-stage quality controls, semi-automatic annotations, and automatic work allocation to maximize the speed and accuracy of annotators",
}

type CustomizationFeatConent = Omit<FeatureRowProps, "textContent"> & {
  textContent: CustomizationFeatContentProps
}
export const CUSTOMIZATION_FEATS_PROPS: CustomizationFeatConent[]  = [
  {
    imgUrl: QAImg,
    textContent: {
      title: "Guarantee the annotation output with a well-established QA process",
      listItems: [
        "Multiple review rounds for each annotation project.",
        "Continuous quality inspection by clients."
      ]
    },
    isImgLeft: true,
  },
  {
    imgUrl: InteractiveImg,
    textContent: {
      title: "Accelerate the annotation process with Graviton’s state-of-art models.",
      listItems: [
        "Pre-trained models with a wide range of classes per industry.",
        "Can assisting layer to reduce annotation errors."
      ]
    },
    isImgLeft: false,
  },
  {
    imgUrl: DynamicAllocationImg,
    textContent: {
      title: "Dynamic task allocation",
      listItems: [
        "Built-in work allocation engine to maximize the throughput of annotators.",
        "Workforce scaling by adding new annotators to the task."
      ]
    },
    isImgLeft: true,
  },
]

export const TOOL_FEATS_SECTION_TITLE_PROPS: SectionTitleProps = {
  sectName: "Fast speed at high quality",
  title: "Bring in a world-class workforce",
  subTitle: "Our workforce is experienced with various annotation types",
}

export const ANNOTATION_FEAT_CONTENT: AnnotationButtonContent[] = [
  {
    iconUrl: "https://assets-global.website-files.com/5b26e3fda3234fe366aa392d/625704ded2d68e67f08ecf10_Scale%20your%20workforce.png",
    text: "Object Detection",
    imgUrl: "https://assets-global.website-files.com/5b26e3fda3234fe366aa392d/625704ded2d68e67f08ecf10_Scale%20your%20workforce.png",
  },
  {
    iconUrl: "https://assets-global.website-files.com/5b26e3fda3234fe366aa392d/625704ded2d68e67f08ecf10_Scale%20your%20workforce.png",
    text: "Sematic segmentation",
    imgUrl: "https://assets-global.website-files.com/5b26e3fda3234fe366aa392d/625704ded2d68e67f08ecf10_Scale%20your%20workforce.png",
  },
  {
    iconUrl: "https://assets-global.website-files.com/5b26e3fda3234fe366aa392d/625704ded2d68e67f08ecf10_Scale%20your%20workforce.png",
    text: "Instance segmentation",
    imgUrl: "https://assets-global.website-files.com/5b26e3fda3234fe366aa392d/625704ded2d68e67f08ecf10_Scale%20your%20workforce.png",
  },
  {
    iconUrl: "https://assets-global.website-files.com/5b26e3fda3234fe366aa392d/625704ded2d68e67f08ecf10_Scale%20your%20workforce.png",
    text: "Image classification",
    imgUrl: "https://assets-global.website-files.com/5b26e3fda3234fe366aa392d/625704ded2d68e67f08ecf10_Scale%20your%20workforce.png",
  },
  {
    iconUrl: "https://assets-global.website-files.com/5b26e3fda3234fe366aa392d/625704ded2d68e67f08ecf10_Scale%20your%20workforce.png",
    text: "Point cloud annotation",
    imgUrl: "https://assets-global.website-files.com/5b26e3fda3234fe366aa392d/625704ded2d68e67f08ecf10_Scale%20your%20workforce.png",
  },
  {
    iconUrl: "https://assets-global.website-files.com/5b26e3fda3234fe366aa392d/625704ded2d68e67f08ecf10_Scale%20your%20workforce.png",
    text: "Attribute annotation",
    imgUrl: "https://assets-global.website-files.com/5b26e3fda3234fe366aa392d/625704ded2d68e67f08ecf10_Scale%20your%20workforce.png",
  },
  {
    iconUrl: "https://assets-global.website-files.com/5b26e3fda3234fe366aa392d/625704ded2d68e67f08ecf10_Scale%20your%20workforce.png",
    text: "Polyline annotation",
    imgUrl: "https://assets-global.website-files.com/5b26e3fda3234fe366aa392d/625704ded2d68e67f08ecf10_Scale%20your%20workforce.png",
  },
  {
    iconUrl: "https://assets-global.website-files.com/5b26e3fda3234fe366aa392d/625704ded2d68e67f08ecf10_Scale%20your%20workforce.png",
    text: "Point annotation",
    imgUrl: "https://assets-global.website-files.com/5b26e3fda3234fe366aa392d/625704ded2d68e67f08ecf10_Scale%20your%20workforce.png",
  },
]

export const INDUSTRY_SECTION_TITLE_PROPS: SectionTitleProps = {
  sectName: "Industry experience",
  title: "Industries we experienced with",
  subTitle: "Explore how we deliver the values in different use cases.",
  buttonLink: "./",
  buttonText:  "See all industry →"
}

export const INDUSTRY_ITEMS: IndustryItemContent[] = [
  {
    title: "Automotive",
    iconUrl: "https://assets-global.website-files.com/5d7b77b063a9066d83e1209c/621266546f6e240dd1b2b331_manufacturing.svg",
    videoUrl: "https://darwin-public.s3.eu-west-1.amazonaws.com/splash_page/Auto+Ann+-+Automotive_compressed.mp4",
  },
  {
    title: "Manufacturing",
    iconUrl: "https://assets-global.website-files.com/5d7b77b063a9066d83e1209c/621266546f6e240dd1b2b331_manufacturing.svg",
    videoUrl: "https://darwin-public.s3.eu-west-1.amazonaws.com/splash_page/Auto+Ann+-+Automotive_compressed.mp4",
  },
  {
    title: "Healthcare",
    iconUrl: "https://assets-global.website-files.com/5d7b77b063a9066d83e1209c/621266546f6e240dd1b2b331_manufacturing.svg",
    videoUrl: "https://darwin-public.s3.eu-west-1.amazonaws.com/splash_page/Auto+Ann+-+Automotive_compressed.mp4",
  },
  {
    title: "Agriculture",
    iconUrl: "https://assets-global.website-files.com/5d7b77b063a9066d83e1209c/621266546f6e240dd1b2b331_manufacturing.svg",
    videoUrl: "https://darwin-public.s3.eu-west-1.amazonaws.com/splash_page/Auto+Ann+-+Automotive_compressed.mp4",
  },
]
