import React from "react";

import Header from "../../partials/Header";
import Footer from "../../partials/Footer";
import MainPageBanner from "../../partials/mainPage/mainPageBanner/MainPageBanner";
import "./style.scss";
import Companies from "../../partials/companies/Companies";
import MainPageFunctions from "../../partials/mainPage/mainPageFunc/MainPageFunctions";
import MainPageNavListing from "../../partials/mainPage/mainPageNavListing/MainPageNavListing";
import Comments from "../../partials/comments/Comments";
import Industry from "../../partials/mainPage/mainPageIndustry/Industry";
import ContactUs from "../../partials/contactus/ContactUs";

import LeftImage from "../../images/main-page-banner-1.png";
import MidImage from "../../images/main-page-banner-2.png";
import RightImage from "../../images/main-page-banner-3.png";
import {Helmet} from "react-helmet";

function Home() {
	return (
		<div className="flex flex-col min-h-screen overflow-hidden">
			<Helmet>
				<title>Linh - Bring AI to Life</title>
				<meta name="description" content="Linh AI empowers people to build smarter, faster, and better AI by providing advanced tools and high quality data annotation." />
			</Helmet>
			{/*  Site header */}
			<Header dark={false}/>
			<div className="bg-main">
				<div className="container mx-auto home-container">
					<MainPageBanner
						title={`The Data-Centric Platform for ML Models`}
						subtitle="Accelerate AI development by centralizing the data tools and processes in one place"
						imgSrcs={[LeftImage, MidImage, RightImage]}
					/>
				</div>
			</div>
			<Companies headline="Empowering the machine learning team at"/>
			<div className="container mx-auto function-container">
				<MainPageFunctions/>
			</div>

			<div className="container mx-auto function-container">
				<MainPageNavListing/>
			</div>
			<Comments/>

			<div className="container mx-auto function-container">
				<Industry/>
			</div>

			<ContactUs/>
			{/*  Site footer */}
			<Footer/>
		</div>
	);
}

export default Home;
