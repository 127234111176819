import FunctionHeader from "./FunctionHeader";
import {useState} from "react";
import FunctionContent from "./FunctionContent";
import Button from "../../button/Button";
import './style.scss';

export type FunctionOption = {
	title: string;
	icon: any;
	contentTitle: string;
	contentSubTitle: string;
	demoImg: string;
}

export type Position = 'start' | 'end';
export interface FunctionInterface {
	title: string;
	subTitle: string;
	options: FunctionOption[],
	position?: Position,
	actionUrl?: string;
	actionBtn?: string
}

export default function Function(props: FunctionInterface) {
	const {title, subTitle, options, position, actionUrl, actionBtn} = props;
	const [selected, setSelected] = useState<number>(0);
	const onSelect = (id: number) => {
		setSelected(id);
	}

	return (
		<div className={`function-block ${position === 'start' ? 'start-bg' : ''}`} >
			<FunctionHeader
				title={title}
				subtitle={subTitle}
				options={options}
				onSelect={onSelect}
				selected={selected}
			/>
			{options.map((_o, index) => {
				return (
					<FunctionContent
						contentTitle={_o.contentTitle}
						contentSubTitle={_o.contentSubTitle}
						demoImg={_o.demoImg}
						position={position}
						display={index === selected}
					/>
				)
			})}
			{actionBtn && (<div className='action-btn'>
				<Button title={actionBtn} href={actionUrl}/>
			</div>)}
		</div>
	)
}
