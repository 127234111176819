import React from "react";
import './style.scss'
import {AiOutlineArrowRight} from "react-icons/all";
import {useHistory} from "react-router-dom";

export interface TypeFormBtnProps {
	text?: string;
	icon?: boolean;
	position?: 'left' | 'right' | 'center';
}

export default function TypeFormBtn(props: TypeFormBtnProps) {
	const {text, icon} = props;
	const history = useHistory();
	return (
		<button
			data-tf-popup="ezU0KeBU"
			data-tf-size="70"
			data-tf-iframe-props="title=Early Access"
			data-tf-medium="snippet"
			data-tf-hidden="utm_source=xxxxx,utm_medium=xxxxx,utm_content=xxxxx"
			className="btn typeform-btn"
			data-aos="fade-up"
			onClick={() => {
				history.push('/contact')
			}}
		>
			<span style={{whiteSpace: 'nowrap'}}>{text ? text : 'Contact us'}</span>
			{icon && (<AiOutlineArrowRight style={{marginLeft: 8}} fontSize={16}/>)}
		</button>
	)
}
