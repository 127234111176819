import './style.scss';

export interface ButtonInterface {
	title: string;
	icon?: any;
	bgColor?: string;
	fontColor?: string;
	className?: string;
	href?: string;
}

export default function Button(props: ButtonInterface) {
	const {title, icon, bgColor, fontColor, className, href} = props;
	return (
		<a
			className={`btn btn-custom ${className ? className : ''}`}
			href={href ? href : '#'}
			style={{
				color: fontColor ? fontColor : 'white',
				backgroundColor: bgColor ? bgColor : '#0072f4',
				whiteSpace: 'nowrap',
			}}
		>
			<p>{title}</p>
			{icon ? icon : null}
		</a>
	)
}
