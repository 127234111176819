import React from 'react';
import DPCapital from '../../images/dp-capital.png';

const CompanyBackground = () => {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="text-center mt-20">
          <h3 className="h2 mb-10" data-aos="fade-up">
            We are backed by
          </h3>
        </div>

        <div
          className="grid gap-6 lg:grid-cols-4 md:grid-cols-3 grid-cols-2 mb-8"
          data-aos-id-clients
        >
          <div
            className="flex items-center justify-center  p-6"
            data-aos="zoom-y-out"
            data-aos-anchor="[data-aos-id-clients]"
          >
            <img
              src="https://linh-sare.s3.ap-southeast-1.amazonaws.com/ntu.png"
              alt="NTU"
              className="w-auto h-auto max-w-full"
            />
          </div>

          <div
            className="flex items-center justify-center  p-6"
            data-aos="zoom-y-out"
            data-aos-anchor="[data-aos-id-clients]"
          >
            <img
              src="https://linh-sare.s3.ap-southeast-1.amazonaws.com/ntuitive.png"
              alt="NTUitive"
              width="150"
              height="40"
            />
          </div>

          <div
            className="flex items-center justify-center  p-6"
            data-aos="zoom-y-out"
            data-aos-anchor="[data-aos-id-clients]"
          >
            <img
              src="https://linh-sare.s3.ap-southeast-1.amazonaws.com/ilab.png"
              alt="Ilab"
              width="150"
              height="40"
            />
          </div>

          <div
            className="flex items-center justify-center  p-6"
            data-aos="zoom-y-out"
            data-aos-anchor="[data-aos-id-clients]"
          >
            <img
              src="https://linh-sare.s3.ap-southeast-1.amazonaws.com/garage.png"
              alt="Garage"
              className="w-auto h-auto max-w-full"
            />
          </div>

          <div
            className="flex items-center justify-center  p-6"
            data-aos="zoom-y-out"
            data-aos-anchor="[data-aos-id-clients]"
          >
            <img
              src="https://linh-sare.s3.ap-southeast-1.amazonaws.com/awsactivate.png"
              alt="AWS"
              className="w-auto h-auto max-w-full"
            />
          </div>

          <div
            className="flex items-center justify-center  p-6"
            data-aos="zoom-y-out"
            data-aos-anchor="[data-aos-id-clients]"
          >
            <img
              src="https://linh-sare.s3.ap-southeast-1.amazonaws.com/nvidia.png"
              alt="NVidia"
              className="w-auto h-auto max-w-full"
            />
          </div>

          <div
            className="flex items-center justify-center  p-6"
            data-aos="zoom-y-out"
            data-aos-anchor="[data-aos-id-clients]"
          >
            <img
              src="https://linh-sare.s3.ap-southeast-1.amazonaws.com/Republic+logo.png"
              alt="Republic Poly"
              className="w-auto h-auto max-w-full"
            />
          </div>
          <div
              className="flex items-center justify-center p-6"
              data-aos="zoom-y-out"
              data-aos-anchor="[data-aos-id-clients]"
          >
            <img
                src={DPCapital}
                alt="DP Capital Partners"
                className="w-auto h-auto max-w-full"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default CompanyBackground
