import React, {useEffect} from "react";

import Header from "partials/Header";
import Footer from "partials/Footer";

import HeroPlatform from "partials/platform/Hero";
import {FeatureBoxesRow} from "partials/platform/FeatureBox";
import FeatureDemo from "partials/platform/AnnotationFeaturesDemo";
import ContactUs from "../../partials/contactus/ContactUs";
import {Helmet} from "react-helmet";

type Props = {
	title: string;
}
const DataAnnotation: React.FC<Props> = ({title}) => {
	useEffect(() => {
		document.title = title;
	});

	return (
		<div className="flex flex-col min-h-screen overflow-hidden">
			{/*  Site header */}
			<Helmet>
				<title>Linh - Data annotation tools</title>
				<meta name="description" content="We provide advanced annotations tools including semi-automatic annotation to serve AI industry" />
			</Helmet>
			<Header dark/>

			{/*  Page content */}
			<main className="flex-grow">
				{/*  Page illustration */}
				<HeroPlatform title="Design for better data"
							  supportText="Get high-quality ground truths 6x faster with our advanced annotation tools"
							  invertColor className="platform-hero platform-annotation"
				/>
				<div className="flex flex-col gap-y-10 lg:gap-y-15 platform-main p-4 sm:p-10 md:p-20">
					<FeatureBoxesRow
						data={[
							{
								title: "Semi-automation",
								content: "Speed up 6x annotation process with our built-in AI model. Work on many different annotation classes.",
								imgUrl: require('../../images/semi-automatic.png').default
							},
							{
								title: "Metadata annotation",
								content: "Add attributes, text, and grouping to object or image to provide more information to your annotation.",
								imgUrl: require('../../images/metadata-annotation.png').default
							},
						]}
					/>
					<FeatureBoxesRow
						data={[
							{
								title: "Comprehensive quality assurance",
								content: "Ensure annotation accuracy at scale with customized review workflows. Automated test to spot common annotation errors.",
								imgUrl: require('../../images/quality-assurance.png').default
							},
							{
								title: "Annotation metrics",
								content: "Gain visibility into your team performance with interactive statistics: annotation per minute, accuracy metrics and more.",
								imgUrl: require('../../images/performance-insights.png').default
							},
						]}
					/>
					<FeatureBoxesRow
						data={[
							{
								title: "Pilot batch",
								content: "Continuously explore different scenarios in your dataset and update the annotation requirements. Calibrate annotator’s work quality to match your expectation.",
								imgUrl: require('../../images/calibration.png').default
							},
							{
								title: "Security",
								content: "Annotators have limited permissions to explore the dataset. All of our services are hosted with Amazon Web Servies (AWS). They employ a robust security program with multiple certifications.",
								imgUrl: require('../../images/data-security.png').default
							},
						]}
					/>
					<FeatureDemo/>
					<ContactUs title='Talk to our expert and schedule a demo'/>
				</div>
				{/*  Site footer */}
				<Footer/>
			</main>
		</div>
	);
}

export default DataAnnotation;

