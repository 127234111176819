import './style.scss';

export interface CardInterface {
	title: string;
	subTitle: string;
	img: string;
	url: string;
}

export default function Card(props: CardInterface) {
	const {title, subTitle, img, url} = props;
	return (
		<a className='card-block' href={url}>
			<img className='img-card' src={img} />
			<div className='text-block'>
				<p className='text-base font-medium'>{title}</p>
				<p className='text-sm'>{subTitle}</p>
				<button className='explore-btn'>Explore now</button>
			</div>
		</a>
	)
}
