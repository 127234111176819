import ParagraphBlock from "../paragraphBlock/ParagraphBlock";
import ToolChip from "../toolChip/ToolChip";
import {
  AiFillCloud,
  AiFillTag,
  AiOutlineNodeIndex,
  CgEditBlackPoint,
  FaObjectUngroup,
  GrLinkNext,
  MdEditAttributes,
  RiImageEditFill,
  TbPolygon
} from "react-icons/all";
import InstanceSegmentation from "../../images/instance-segmentation.png";
import SemanticSegmentation from "../../images/semantic-seg.png";
import ObjectDetection from "../../images/cuboid.png";
import Classification from "../../images/classification.png";
import LaneLine from "../../images/laneline.png";
import Point from "../../images/point.png";
import PointCloud from "../../images/point-cloud.png";
import Attribute from "../../images/metadat-annotation.png";
import "./style.scss";
import Button from "../button/Button";
import { useState } from "react";

export interface ToolDemoInterface {
  headline?: string,
  title: string,
  subtitle?: string
}

const tools = [
  {
    title: "Object Detection",
    icon: <FaObjectUngroup fontSize={14} color="white" />,
    demoImg: ObjectDetection
  },
  {
    title: "Semantic Segmentation",
    icon: <RiImageEditFill fontSize={14} color="white" />,
    demoImg: SemanticSegmentation,
  },
  {
    title: "Polyline Annotation",
    icon: <AiOutlineNodeIndex fontSize={14} color="white" />,
    demoImg: LaneLine
  },
  {
    title: "Instance Segmentation",
    icon: <TbPolygon fontSize={14} color="white" />,
    demoImg: InstanceSegmentation,
  },
  {
    title: "Image Classification",
    icon: <AiFillTag fontSize={14} color="white" />,
    demoImg: Classification
  },
  {
    title: "Point Cloud Annotation",
    icon: <AiFillCloud fontSize={14} color="white" />,
    demoImg: PointCloud
  },
  {
    title: "Attribute Annotation",
    icon: <MdEditAttributes fontSize={14} color="white" />,
    demoImg: Attribute
  },
  {
    title: "Point Annotation",
    icon: <CgEditBlackPoint fontSize={14} color="white" />,
    demoImg: Point
  }
];

export default function ToolDemo(props: ToolDemoInterface) {
  const { headline, title, subtitle } = props;
  const [selected, setSelected] = useState<number>(0);
  const onClick = (id: number): void => {
    setSelected(id);
  };

  const renderTools = () => {
    const results: JSX.Element[] = [];
    tools.forEach((_t, index) => {
      results.push(<ToolChip key={index} onClick={onClick} id={index} active={index === selected}
                             title={_t.title} icon={_t.icon} />);
    });

    return results;
  };

  return (
    <div className="p-4">
      <ParagraphBlock
        headline={headline}
        title={title}
        headingLevel="h2"
        subtitle={subtitle}
      />
      <div className="flex flex-col flex-nowrap md:flex-row md:flex-wrap p-4" style={{ gap: 8 }}>
        <div className="flex flex-col justify-center flex-1" style={{ gap: 32 }}>
          <div className="flex justify-start w-full md:w-1/2 overflow-control">
            {renderTools()}
          </div>
          <Button href='/contact' className="btn-margin" title="Start now"
                  icon={<GrLinkNext className="btn-icon" fontSize={16} />} />
        </div>
        <div className="w-full md:w-1/2 aspect-img-control">
          <img src={tools[selected].demoImg} />
        </div>
      </div>
    </div>
  );
}
