import './style.scss';
import TypeFormBtn from "../typeFormBtn/TypeFormBtn";

export interface ContactUsProps {
	title?: string;
}

export default function ContactUs(props: ContactUsProps) {
	const {title} = props;
	return (
		<div className='contact-block-size'>
			<div className='flex flex-row justify-between items-center center-contact '>
				<p className='font-medium text-lg text-center'>{title ? title : 'Get better data by talking to our experts!'}</p>
				<div>
					<TypeFormBtn position='center'/>
				</div>
			</div>
		</div>
	)
}
