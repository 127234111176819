import Card from "./Card";
import './style.scss'
import ParagraphBlock from "../../paragraphBlock/ParagraphBlock";

const cards = [
	{
		title: 'Data Annotation',
		subTitle: 'Powerful annotation tools, support various data formats, automation with few clicks',
		img: require('../../../images/data-annotation.png').default,
		url: '/platform/data-annotation'
	},
	{
		title: 'Dataset Management',
		subTitle: 'Standardize data management process, provide fast and efficient data exploration tools',
		img: require('../../../images/dataset-management.png').default,
		url: '/platform/data-management',
	},
	{
		title: 'Model Evaluation',
		subTitle: 'Diagnose model performance, compare with ground truth and identify common problems',
		img: require('../../../images/model-insights.png').default,
		url: '/platform/model-evaluation',
	},
]

export default function MainPageNavListing() {
	const renderCards = () => {
		const result: JSX.Element[] = [];
		cards.forEach((card, index) => {
			result.push(<Card key={index} {...card} />);
		});
		return result;
	}

	return (
		<>
			<ParagraphBlock
				title='Manage your AI development with our Data-Centric platform'
				headingLevel="h2"
				headline='The data platform'/>
			<div className='grid grid-style'>
				{renderCards()}
			</div>
		</>
	)
}
