import React from 'react'

import Header from '../partials/Header'
import ProductFeaturesZigzag from '../partials/product/ProductFeaturesZigzag'
import Process from '../partials/product/Process'
import WhyGraviton from '../partials/WhyGraviton'
import Cta from '../partials/Cta'
import Footer from '../partials/Footer'
import HeroProduct from '../partials/product/HeroProduct'

function Features() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header graviton dark />

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page illustration */}

        {/*  Page sections */}

        <HeroProduct />

        <Process />
        <ProductFeaturesZigzag />
        {/* <Demo /> */}
        <WhyGraviton />
        {/* <CaseStudies /> */}
        {/* <News /> */}
        <Cta />
        <div
          className="elative max-w-6xl mx-auto h-0 pointer-events-none z-0"
          aria-hidden="true"
        >
          <svg
            width="1360"
            height="1000"
            viewBox="0 0 1360 578"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <linearGradient
                x1="50%"
                y1="0%"
                x2="50%"
                y2="100%"
                id="illustration-01"
              >
                <stop stopColor="#FFF" offset="0%" />
                <stop stopColor="#EAEAEA" offset="77.402%" />
                <stop stopColor="#DFDFDF" offset="100%" />
              </linearGradient>
            </defs>
            <g fill="url(#illustration-01)" fillRule="evenodd">
              <circle cx="1232" cy="408" r="128" />
              <circle cx="64" cy="200" r="64" />
            </g>
          </svg>
        </div>
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  )
}

export default Features
