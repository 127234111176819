import React from 'react'
import Accordion from '../utils/Accordion'

function Faqs() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-200">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-16">
            <h2 className="h2">Frequently asked questions</h2>
          </div>

          {/* Faqs */}
          <ul className="max-w-3xl mx-auto pl-12">
            <Accordion title="Does Linh AI offer annotation services?">
              Yes. Our well-trained annotators will help you turn your data into
              ground truth. Submit the annotation service request and our Project
              Manager will get back to you within hours. Register with us today
              to redeem up to $30 of labelling credit.
            </Accordion>
            <Accordion title="There may be additional constraints and requirements for my project. Who should I contact?">
              A dedicated Project Manager will collaborate with you to meet your
              needs. Please reach out to our <a style={{color: "#0000EE"}} href="/contact">sales team</a>.
            </Accordion>
            <Accordion title="How can I verify the quality of the annotation service?">
              We provide you with a customized dashboard for inspecting
              annotation output. You may process a small amount of data at the
              calibration batch to check the quality of the output.
            </Accordion>
            <Accordion title="Does Linh AI follow any security practices?">
              We take data protection very seriously. Our platform is SOC 2 and ISO 27001
              compliant. For more details, please learn
              more <a style={{color: "#0000EE"}} href="https://docs.linh.ai/data-security/company-security" target="_blank">here</a>.
            </Accordion>
            <span
              className="block border-t border-gray-200"
              aria-hidden="true"
            ></span>
          </ul>
        </div>
      </div>
    </section>
  )
}

export default Faqs
