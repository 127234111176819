import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { Provider } from 'react-redux'
import ReactGA from 'react-ga'

import App from './App'

import * as serviceWorker from './serviceWorker'

import store from './utils/store'

const trackingId = 'G-5EVFP4E1BX' // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId)
ReactGA.set({
  // any data that is relevant to the user session
  // that you would like to track with google analytics
})

const history = createBrowserHistory()

// Initialize google analytics page view tracking
history.listen((location) => {
  ReactGA.set({ page: location.pathname }) // Update the user's current page
  ReactGA.pageview(location.pathname) // Record a pageview for the given page
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
