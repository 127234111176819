import React from 'react'
import EarlyAccessButton from '../earlyAccess/EarlyAccessButton'

function FeaturesZigzag() {
  return (
    <section>
      <div className="flex justify-center items-center mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 max-w-6xl">
          {/* Section header */}
          <div className="max-w-6xl mx-auto text-center pb-12 md:pb-16">
            {/* <div className="inline-flex text-sm font-semibold py-1 px-3 m-2 text-green-600 bg-green-200 rounded-full mb-4"></div> */}
            <h1 className="h2 mb-4">Features</h1>
            <p className="text-xl text-gray-800">
              Our product is completely customizable, with full integration
              support.
            </p>
          </div>

          {/* Items */}
          <div className="grid gap-20">
            {/* 1st item */}
            <div className="md:grid md:grid-cols-12 md:gap-3 items-center">
              {/* Image */}
              <div
                className="relative max-w-2xl md:max-w-full md:w-full mx-auto md:col-span-8 lg:col-span-8 mb-8 md:mb-0 md:order-1"
                data-aos="fade-up"
              >
                <div className="relative pl-10">
                  <img
                    className="max-w-full mx-auto md:max-w-full h-auto"
                    src="https://linh-sare.s3.ap-southeast-1.amazonaws.com/Data+management.png"
                    width="700"
                    height="405"
                    alt="Features 01"
                  />
                </div>
              </div>
              {/* Content */}
              <div
                className="max-w-xl md:max-w-full md:w-full mx-auto md:col-span-4 lg:col-span-4"
                data-aos="fade-right"
              >
                <div className="md:pr-4 lg:pr-12 xl:pr-16">
                  <div className="font-architects-daughter text-xl text-blue-600 mb-2">
                    Upload & Manage
                  </div>
                  <h3 className="h3 mb-3">Dataset Management</h3>
                  <p className="text-lg text-gray-800 mb-4">
                    Upload and version control datasets, models & inferences.
                    Visualize your data to identify edge cases.
                  </p>
                  <div>
                    <EarlyAccessButton />
                  </div>
                </div>
              </div>
            </div>

            {/* 2nd item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
              {/* Image */}
              <div
                className="max-w-xl md:max-w-full md:w-full mx-auto md:col-span-5 lg:col-span-7 mb-8 md:mb-0 md:rtl"
                data-aos="fade-up"
              >
                <img
                  className="max-w-full mx-auto md:max-w-full h-auto drop-shadow shadow-xl"
                  src="https://linh-sare.s3.ap-southeast-1.amazonaws.com/Deduplication.png"
                  alt="Features 02"
                />
              </div>
              {/* Content */}
              <div
                className="max-w-xl md:max-w-full md:w-full mx-auto md:col-span-7 lg:col-span-5"
                data-aos="fade-left"
              >
                <div className="md:pl-4 lg:pl-12 xl:pl-16">
                  <div className="font-architects-daughter text-xl text-blue-600 mb-2">
                    Curate
                  </div>
                  <h3 className="h3 mb-3">Data Explorer & Active Learning</h3>
                  <p className="text-lg text-gray-600 mb-4">
                    Apply deduplcation and active learning to select the most
                    valuable data points in your database to annotate.
                  </p>
                  <div>
                    <EarlyAccessButton />
                  </div>
                </div>
              </div>
            </div>

            {/* 3rd item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
              {/* Image */}
              <div
                className="relative max-w-11/2 md:w-full mx-auto md:col-span-7 lg:col-span-7 mb-8 md:mb-0 md:order-1"
                data-aos="fade-up"
              >
                <img
                  className="max-w-full mx-auto h-auto"
                  src="https://linh-sare.s3.ap-southeast-1.amazonaws.com/Annotation+%26+QC.png"
                  alt="Features 03"
                />
              </div>
              {/* Content */}
              <div
                className="max-w-xl md:max-w-full md:w-full mx-auto md:col-span-5 lg:col-span-5"
                data-aos="fade-right"
              >
                <div className="md:pr-4 lg:pr-12 xl:pr-16">
                  <div className="font-architects-daughter text-xl text-blue-600 mb-2">
                    Annotate
                  </div>
                  <h3 className="h3 mb-3">Annotation & QC Tools</h3>
                  <p className="text-lg text-gray-600 mb-4">
                    Get high-quality ground truths with our integrated
                    annotation tools and on-demand annotation services.
                  </p>
                  <div>
                    <EarlyAccessButton />
                  </div>
                </div>
              </div>
            </div>

            {/* 4th item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
              {/* Image */}
              <div
                className="max-w-xl md:max-w-full md:w-full md:col-span-5 lg:col-span-7 mb-8 md:mb-0 md:rtl"
                data-aos="fade-up"
              >
                <img
                  className="max-w-full mr-auto md:max-w-full h-auto drop-shadow shadow-xl"
                  src="https://linh-sare.s3.ap-southeast-1.amazonaws.com/Model+Evaluation.png"
                  width="640"
                  alt="Features 02"
                />
              </div>
              {/* Content */}
              <div
                className="max-w-xl md:max-w-full md:w-full mx-auto md:col-span-7 lg:col-span-5"
                data-aos="fade-left"
              >
                <div className="md:pl-4 lg:pl-12 xl:pl-16">
                  <div className="font-architects-daughter text-xl text-blue-600 mb-2">
                    Evaluate
                  </div>
                  <h3 className="h3 mb-3">Model Evaluation</h3>
                  <p className="text-lg text-gray-600 mb-4">
                    Automatic, Visual and Qualitative way of interpreting and
                    comparing ML models' behaviours.
                  </p>
                  <div>
                    <EarlyAccessButton />
                  </div>
                </div>
              </div>
            </div>

            {/* 5th item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
              {/* Image */}
              <div
                className="max-w-xl md:max-w-full md:w-full mx-auto md:col-span-5 lg:col-span-8 mb-8 md:mb-0 md:order-1"
                data-aos="fade-up"
              >
                <img
                  className="max-w-full mx-auto md:max-w-full h-auto drop-shadow shadow-xl"
                  src="https://linh-sare.s3.ap-southeast-1.amazonaws.com/Edge+case+mining.png"
                  width="750"
                  height="405"
                  alt="Features 03"
                />
              </div>
              {/* Content */}
              <div
                className="max-w-xl md:max-w-full md:w-full mx-auto md:col-span-7 lg:col-span-4"
                data-aos="fade-right"
              >
                <div className="md:pr-4 lg:pr-12 xl:pr-16">
                  <div className="font-architects-daughter text-xl text-blue-600 mb-2">
                    Find Edge Cases
                  </div>
                  <h3 className="h3 mb-3">Edge Case Mining</h3>
                  <p className="text-lg text-gray-600 mb-4">
                    Get high-quality ground truths with our integrated
                    annotation tools and on-demand annotation services.
                  </p>
                  <div>
                    <EarlyAccessButton />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeaturesZigzag
