import React from 'react';

export type Props = {
  selected: boolean
  icon: any;
  onClick: () => any;
}

const FeatButton: React.FC<Props> = ({
  selected,
  icon,
  onClick
}) => {
  return (
    <div className={`platform-box-button ${selected? "selected" : ""}`} onClick={onClick}>
      {icon}
    </div>
  )
}

export default FeatButton;
