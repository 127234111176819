import ParagraphBlock from "../../paragraphBlock/ParagraphBlock";
import MultipleIndustryCards from "../../industryCard/MultipleIndustryCards";
import { AiOutlineCar, MdOutlineHealthAndSafety, RiPlantLine, VscServerProcess } from "react-icons/all";
import { IndustryCardInterface } from "../../industryCard/IndustryCard";
import AutomotiveImg from "../../../images/automotive.png";
import ManufactureImg from "../../../images/manufacture.png";
import HealthCareImg from "../../../images/healthcare.png";
import AgricultureImg from "../../../images/agriculture.png";

const industries: IndustryCardInterface[] = [
  {
    title: "Automotive",
    icon: <AiOutlineCar fontSize={16} />,
    imgSrc: AutomotiveImg,
    linkUrl: "/industries/automotive",
  },
  {
    title: "Manufacturing",
    icon: <VscServerProcess fontSize={16} />,
    imgSrc: ManufactureImg,
    linkUrl: "/industries/manufacturing",
  },
  {
    title: "Healthcare & Life Sciences",
    icon: <MdOutlineHealthAndSafety fontSize={20} />,
    imgSrc: HealthCareImg,
    linkUrl: "/industries/healthcare",
  },
  {
    title: "Agriculture",
    icon: <RiPlantLine fontSize={16} />,
    imgSrc: AgricultureImg,
    linkUrl: "/industries/agriculture"
  }
];

export default function Industry() {
  return (
    <div style={{ marginTop: 32 }}>
      <ParagraphBlock title="Contributing to AI development across industries"
                      headingLevel="h2"
                      headline="Customized tools for industries" />
      <MultipleIndustryCards industries={industries} />
    </div>
  );
}
