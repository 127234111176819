import React, { useEffect } from "react";

import Header from "../partials/Header";

import HeroAbout from "../partials/HeroAbout";
import TeamImages from "../partials/TeamImages";
import Timeline from "../partials/Timeline";
import Background from "../partials/Background";
import Cta from "../partials/CtaAlternative";
import Footer from "../partials/Footer";
import CoreValues from "../partials/CoreValues";
import BubbleIllustration from "../partials/BubbleIllustration";

function About({ title }) {
  useEffect(() => {
    document.title = title;
  });

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page illustration */}

        <BubbleIllustration />

        {/*  Page sections */}
        <HeroAbout />
        <TeamImages />
        <Timeline />
        <CoreValues />
        <Background />
        {/* <TestimonialsCarousel /> */}
        {/* <Career /> */}
        {/* <Clients /> */}
        <Cta />
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default About;
