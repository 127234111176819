import React, { useRef } from "react";
import { useHistory } from "react-router";


import "./style.scss";

const $ = require("jquery");


function GetEmail() {
  const history: any = useHistory();

  const inputEl: any = useRef(null);
  const submitEl: any = useRef(null);
  const antiSpamEl: any = useRef(null);

  const handleChange = () => {
    inputEl.current.setCustomValidity("");
  };

  const handleClick = () => {
    // Email validation
    const emailList = /^([\w-\.]+@(?!gmail.com)(?!yahoo.com)(?!icloud.com)(?!hotmail.com)(?!aol.com)(?!163.com)(?!ya.ru)(?!hotmail.co.uk)(?!hotmail.fr)(?!email.com)(?!cal.com)(?!365cal.com)(?!msn.com)(?!yahoo.fr)(?!wanadoo.fr)(?!a.com)(?!yandex.ru)(?!yandex.com)(?!gmx.de)(?!mail.ru)(?!edu.in)(?!ac.in)(?!orange.fr)(?!comcast.net)(?!yahoo.co.uk)(?!yahoo.com.br)(?!yahoo.co.in)(?!live.com)(?!rediffmail.com)(?!.ac.in)(?!free.fr)(?!gmx.de)(?!web.de)(?!yandex.ru)(?!ymail.com)(?!libero.it)(?!outlook.com)(?!uol.com.br)(?!bol.com.br)(?!mail.ru)(?!cox.net)(?!hotmail.it)(?!sbcglobal.net)(?!sfr.fr)(?!live.fr)(?!verizon.net)(?!live.co.uk)(?!googlemail.com)(?!yahoo.es)(?!ig.com.br)(?!live.nl)(?!bigpond.com)(?!terra.com.br)(?!yahoo.it)(?!neuf.fr)(?!yahoo.de)(?!alice.it)(?!rocketmail.com)(?!att.net)(?!laposte.net)(?!facebook.com)(?!bellsouth.net)(?!yahoo.in)(?!hotmail.es)(?!charter.net)(?!yahoo.ca)(?!yahoo.com.au)(?!rambler.ru)(?!hotmail.de)(?!tiscali.it)(?!shaw.ca)(?!yahoo.co.jp)(?!sky.com)(?!earthlink.net)(?!optonline.net)(?!freenet.de)(?!t-online.de)(?!aliceadsl.fr)(?!virgilio.it)(?!home.nl)(?!qq.com)(?!telenet.be)(?!me.com)(?!yahoo.com.ar)(?!tiscali.co.uk)(?!yahoo.com.mx)(?!voila.fr)(?!gmx.net)(?!mail.com)(?!planet.nl)(?!tin.it)(?!live.it)(?!ntlworld.com)(?!arcor.de)(?!yahoo.co.id)(?!frontiernet.net)(?!hetnet.nl)(?!live.com.au)(?!yahoo.com.sg)(?!zonnet.nl)(?!club-internet.fr)(?!juno.com)(?!optusnet.com.au)(?!blueyonder.co.uk)(?!bluewin.ch)(?!skynet.be)(?!sympatico.ca)(?!windstream.net)(?!mac.com)(?!centurytel.net)(?!chello.nl)(?!live.ca)(?!qq.com)(?!naver.com)(?!aim.com)(?!bigpond.net.au)([\w-]+\.)+[\w-]{2,4})?$/;

    const emailAlert = "Please input your valid work email address (NOT Gmail, Outlook, Yahoo, etc.)";
    const email = inputEl.current.value.toLowerCase();
    if (emailList.test(email)) {
      inputEl.current.setCustomValidity("");
    } else {
      inputEl.current.setCustomValidity(emailAlert);
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    submitEl.current.value = submitEl.current.getAttribute("data-wait");

    // Send email
    const targetUrl = "https://ntu.us5.list-manage.com/subscribe/post-json?u=27b5cffcd804d74143c5d53ce&id=8432963878";
    const data = {
      "EMAIL": inputEl.current.value,
      "b_27b5cffcd804d74143c5d53ce_8432963878": antiSpamEl.current.value,
      "subscribe": "Subscribe"
    };
    console.log(data);

    $.ajax({
      url: targetUrl,
      method: "GET",
      data: data,
      cache: false,
      dataType: "jsonp",
      jsonp: "c", // trigger MailChimp to return a JSONP response
      contentType: "application/json; charset=utf-8"
    }).always(() => {
        // Redirect to get started page
        history.push("/contact");
      }
    );
  };

  return (
    <>
      <div className={"relative max-w-xs mx-auto w-full"}>
        <form className={"flex p-1 border-1 bg-white rounded-full shadow-lg email-form"}
              onSubmit={handleSubmit}
        >
          <input type="email" ref={inputEl} onChange={handleChange} placeholder="Your work email"
                 aria-label="Your work email" required={true}
                 className={"form-input bg-transparent border-0 text-sm w-full text-gray-500"} />
          <input type="submit" ref={submitEl} value={"Get Started"} data-wait="In progress..." onClick={handleClick}
                 className="w-button blue-button" />
          {/*Anti-spam*/}
          <div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true">
            <input type="text" ref={antiSpamEl}
                   tabIndex={-1} value="" />
          </div>

        </form>
      </div>
    </>
  );
}

export default GetEmail;
