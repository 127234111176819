import React, {useState} from 'react';
import type {StepContent} from './types';
import {STEPS_CONTENT} from './constants';
import classNames from 'classnames';
import "./style.scss";
import {AiOutlineCheck} from "react-icons/all";
import {useInterval} from "../../../utils/hooks";

type SingleStepProps = {
	content: StepContent;
	maxStep: number;
	step: number;
	currentStep: number;
	onClick: (step: number) => any;
	progress: number;
}

const SingleStep: React.FC<SingleStepProps> = ({content, currentStep, maxStep, step, onClick, progress}) => {
	const getPrefixStep = () => {
		if (currentStep <= step) {
			return step + 1;
		}

		return <AiOutlineCheck color='white' fontSize={14}/>
	}

	const changeStep = () => {
		onClick(step);
	}

	return (
		<>
			<div
				className={classNames('arrow-child', step < currentStep ? 'complete-step' : '')}
				onClick={changeStep}
			>
				{currentStep === step && (<div className='progress-cur-step' style={{width: `${progress}%`}}/>)}
				<div
					className={`prefix-step ${currentStep >= step ? 'prefix-step-active' : ''}`}>{getPrefixStep()}</div>
				<div className={classNames(
					"section-text text-sm text-start",
					step <= currentStep ? "highlight" : undefined
				)}>
					{content.stepName}
				</div>
			</div>
			{maxStep > step ?
				<div className={`step-connector ${currentStep > step ? 'complete-connector' : ''}`}/> : null}
		</>
	)
}

type State = {
	stepIndex: number;
	progress: number;
}

const Steps: React.FC = () => {
	const [state, setState] = useState<State>({
		stepIndex: 1,
		progress: 0,
	});

	const onClick = (step: number) => {
		setState({
			stepIndex: step,
			progress: 0,
		})
	}

	useInterval(() => {
		if (state.progress === 99) {
			setState({
				stepIndex: (state.stepIndex + 1) % STEPS_CONTENT.length,
				progress: 0,
			})
		} else {
			setState({
				stepIndex: state.stepIndex,
				progress: (state.progress + 0.5) % 100,
			})
		}
	}, 50)

	return (
		<div className='section-width-control mx-auto'>
			<div className='flex flex-row flex-nowrap arrow-wrapper justify-start'>
				{STEPS_CONTENT.map((stepContent, index) => {
					return (
						<SingleStep
							key={stepContent.stepName}
							onClick={onClick}
							content={stepContent}
							step={index}
							currentStep={state.stepIndex}
							maxStep={STEPS_CONTENT.length - 1}
							progress={state.progress}
						/>
					)
				})}
			</div>
			{STEPS_CONTENT.map((stepContent, index) => {
				return (
					<div key={index} className='flex flex-col md:flex-row platform-box p-5 gap-5 items-center px-10'
						 style={{display: index === state.stepIndex ? 'flex' : 'none'}}>
						<div className='flex-1' data-aos="fade-up">
							<h3 className='text-center md:text-left text-2xl h4'
								style={{marginBottom: 16}}>{stepContent.stepName}</h3>
							<p className='text-left text-base'>{stepContent.content}</p>
						</div>
						<div className='flex-1 img-step-wrapper'>
							<img alt="Different types of objects can be annotated or labeled"
								 className="mx-auto img-flow " data-aos="fade-up" data-aos-delay="400"
								 src={stepContent.imgUrl}/>
						</div>
					</div>
				)
			})}
		</div>
	)
}

export default Steps

