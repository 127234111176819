import React from 'react';

export type Props =  {
  title: string;
  listItems: string[];
}

const CustomizationFeatContent: React.FC<Props> = ({
  title,
  listItems,
}) => {
  return (
    <div className="flex flex-col items-center w-full">
      <div>
        <div className="h3 mb-10 text-2xl">
          {title}
        </div>
        <ul className="custom-section-item-text-list">
          {listItems.map((listItem) => {
            return (
              <li className="text-lg" id={listItem}>{listItem}</li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default CustomizationFeatContent;
