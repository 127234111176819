import React, { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";

import "./scss/style.scss";

import AOS from "aos";
import Sticky from "sticky-js";
import { focusHandling } from "cruip-js-toolkit";

import Home from "./pages/home/Home";
// import SocialImpact from './pages/SocialImpact'
import About from "./pages/About";
import Contact from "./pages/Contact";
// import Industries from "./pages/Industries";
import PageNotFound from "./pages/PageNotFound";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Terms from "./pages/Terms";
import Product from "./pages/Product";
// import Pricing from './pages/Pricing'
import News from "./partials/News";
import Component from "./pages/Component";
import Automotive from "./pages/industries/Automotive";
import Manufacturing from "./pages/industries/Manufacturing";
import Healthcare from "./pages/industries/Healthcare";
import Agriculture from "./pages/industries/Agriculture";

// import Infrastructure from "./pages/Infrastructure";
import DataAnnotation from './pages/Platform/DataAnnotation';
import DataManagement from 'pages/Platform/DataManagement';
import ModelEvaluation from 'pages/Platform/ModelEvaluation';
import AnnotationServices from 'pages/Platform/AnnotationServices';

import AboutUs from './pages/Company'

function App() {
  const location = useLocation();

  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic"
    });
    // eslint-disable-next-line no-unused-vars
    const sticky = new Sticky("[data-sticky]");
  });

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
    focusHandling("outline");
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <Switch>
        <Route exact path="/">
          <Home title="Linh | Bring AI to Life" />
        </Route>
        <Route exact path="/product">
          <Product title="Linh | Product" />
        </Route>
        <Route exact path="/contact">
          <Contact title="Linh | Contact" />
        </Route>

        {/*Industries*/}
        <Route exact path="/industries/automotive">
          <Automotive />
        </Route>
        <Route exact path="/industries/manufacturing">
          <Manufacturing />
        </Route>
        <Route exact path="/industries/healthcare">
          <Healthcare />
        </Route>
        <Route exact path="/industries/agriculture">
          <Agriculture />
        </Route>

        {/* <Route exact path="/pricing">
          <Pricing title="Linh | Pricing" />
        </Route> */}

        <Route exact path="/news">
          <News title="Linh | News" />
        </Route>

        <Route exact path="/privacy-policy">
          <PrivacyPolicy title="Linh | Privacy Policy" />
        </Route>
        <Route exact path="/terms">
          <Terms title="Linh | Terms and Conditions" />
        </Route>
        <Route exact path="/components">
          <Component />
        </Route>
        <Route path="/about">
          <AboutUs title="Linh | About" />
        </Route>
        <Route path="/platform/data-annotation">
          <DataAnnotation title="Linh | Data Annotation" />
        </Route>
        <Route path="/platform/data-management">
          <DataManagement title="Linh | Dataset Management" />
        </Route>
        <Route path="/platform/model-evaluation">
          <ModelEvaluation title="Linh | Model Evaluation" />
        </Route>
        <Route path="/platform/annotation-services">
          <AnnotationServices title="Linh | Annotation Services" />
        </Route>
        <Route path="*">
          <PageNotFound title="Linh | Page Not Found" />
        </Route>
      </Switch>
    </>
  );
}


export default App;
