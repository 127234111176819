import React from 'react'
import classNames from 'classnames'
import TypeFormBtn from "../typeFormBtn/TypeFormBtn";
import './style.scss'

type Props = {
  title: string;
  supportText: string;
  className?: string;
  topOuterDivider?: boolean;
  bottomOuterDivider?: boolean;
  topDivider?: boolean;
  bottomDivider?: boolean;
  hasBgColor?: boolean;
  invertColor?: boolean;
  additionalContent?: any;
  supportTextWhiteColor?: boolean;
}

function HeroPlatform(props: Props) {
  const {
    title,
    supportText,
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    additionalContent,
    supportTextWhiteColor
  } = props

  const outerClasses = classNames(
    'hero full-size section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  )

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    "h-screen"
  )

  const moreInnerClasses = classNames(
    "relative max-content mx-auto px-4 sm:px-6",
    "pt-20 pb-0 md:pt-20 md:pb-20",
    " h-full w-full"
  )
  const contentClasses = "max-w-xl mx-auto md:max-w-none md:flex flex-col gap-8 md:space-x-8 lg:space-x-16 xl:space-x-20 space-y-16 md:space-y-0 h-full platform-hero-banner"

  return (
    <section {...props} className={outerClasses}>
      {/* <PageIllustration /> */}
      {/* Dark background */}
      <div className="container-sm">
        <div className={innerClasses} style={{maxHeight: 800}}>
          <div className={moreInnerClasses}>
              {/* Hero content */}
              <div className={contentClasses}>
                {/* Content */}
                <div
                  className="text-center md:text-left mx-auto flex-col"
                  data-aos="fade-right"
                >
                  <h1 className="h2 text-center text-white mb-4">
                    {title}
                  </h1>
                  <h2 className={`text-xl mb-8 text-center ${supportTextWhiteColor ? 'text-white' : 'text-slate-400'}`}>
                    {supportText}
                  </h2>
                  <div className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center md:justify-center space-y-4 sm:space-y-0 sm:space-x-4">
                    <div>
                      <TypeFormBtn position='center' icon/>
                    </div>
                  </div>
                </div>

                {additionalContent && (<div data-aos="fade-right" style={{marginLeft: "auto", marginRight: "auto"}}>
                  {additionalContent}
                </div>)}

                {/* Hero image */}

                {/*<div data-aos="fade-left object-center" style={{*/}
                {/*  flex: 1*/}
                {/*}}>*/}
                {/*  /!* Image *!/*/}
                {/*  <div*/}
                {/*    className="hero-figure reveal-from-top mt-20"*/}
                {/*  >*/}
                {/*    <img*/}
                {/*      className="mx-auto"*/}
                {/*      src={require("images/platform.gif").default}*/}
                {/*      alt="Hero"*/}
                {/*      data-aos="fade-up"*/}
                {/*      data-aos-delay="400"*/}
                {/*      style={{width: '100%', height: 'auto'}}*/}
                {/*    />*/}
                {/*  </div>*/}
                {/*</div>*/}
              </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroPlatform
