import React, {useEffect} from "react";

import Header from "partials/Header";
import Footer from "partials/Footer";

import HeroPlatform from "partials/platform/Hero";
import {FeatureBoxesRow} from "partials/platform/FeatureBox";
import {BottomBoxesRow} from "partials/platform/BottomBox";
import Possible from "./Possible";
import ContactUs from "../../../partials/contactus/ContactUs";
import Industry from "../../../partials/mainPage/mainPageIndustry/Industry";

type Props = {
	title: string;
}
const DataAnnotation: React.FC<Props> = ({title}) => {
	useEffect(() => {
		document.title = title;
	});

	return (
		<div className="flex flex-col min-h-screen overflow-hidden">
			{/*  Site header */}
			<Header dark/>

			{/*  Page content */}
			<main className="flex-grow">
				{/*  Page illustration */}
				<HeroPlatform
					title="Diagnose your AI models."
					supportText="Visually inspect your model performance and identify all errors"
					invertColor className="platform-hero platform-model"
					additionalContent={<img className='platform-add-on-img' style={{maxWidth: 700}}
											src={require('../../../images/model-eval-add-on.png').default}/>}
				/>
				<div className="flex flex-col gap-y-20 platform-main p-10 md:p-20">
					<FeatureBoxesRow
						data={[
							{
								title: "Inference visualization",
								content: "Evaluate your model’s performance by comparing its output inferences against manually labeled ground truth and using quantitative metrics such as IoU, F1 score, Pixel accuracy score.",
								imgUrl: require('../../../images/model-eval.png').default
							},
							{
								title: "Interactive insights",
								content: "Use confusion matrix to identify the most critical patterns of failures and prioritize the work that improves your model most.",
								imgUrl: require('../../../images/model-insights.png').default
							},
						]}
					/>
					<FeatureBoxesRow
						data={[
							{
								title: "Model iteration",
								content: "Compare the performance of the model across iterations and identify possible variables contributing to the model performance.",
								imgUrl: require('../../../images/model-cmp.png').default
							},
							{
								title: "Edge case mining",
								content: "Identify model’s failure cases and search for visually similar images in your dataset.",
								imgUrl: require('../../../images/edge-case.png').default
							},
						]}
					/>
					<Industry />
					<ContactUs title='Talk to our expert and schedule a demo'/>
				</div>
				{/*  Site footer */}
				<Footer/>
			</main>
		</div>
	);
}

export default DataAnnotation;



