import "./style.scss";

export type Size = "sm" | "md" | "lg";

export interface ToolChipInterface {
  title: string,
  icon: any,
  active: boolean,
  id?: number,
  onClick?: (id: number) => void,
  size?: Size,
}

export default function ToolChip(props: ToolChipInterface) {
  const { title, icon, active, onClick, id, size } = props;
  const onClickEvent = () => {
    if (onClick && id !== undefined) {
      onClick(id);
    }
  };

  const getSizeClass = () => {
    if (size === "md") {
      return "md-size";
    } else if (size === "lg") {
      return "lg-size";
    }
    return "sm-size";
  };

  return (
    <div
      onClick={onClickEvent}
      className={`flex flex-row items-center background${active ? "---active" : ""} ${getSizeClass()}`}
    >
      <div className={`left-icon${active ? "---active" : ""} ${getSizeClass()}`}>
        {icon}
      </div>
      <div className={`right-title${active ? "---active" : ""} ${getSizeClass()}`}>
        {title}
      </div>
    </div>
  );
}
