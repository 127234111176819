import React, { useState } from "react";
import FeatButton from "./FeatButton";
import FeatureContent, { FEATURE_CONTENTS_OBJ } from "./FeatsContent";
import { FEATURE_BUTTON_ICONS, FEATURE_NAME } from "./constants";
import './style.scss';

const FeaturesDemo: React.FC = () => {
  const [selectedFeat, setSelectedFeat] = useState<FEATURE_NAME>(FEATURE_NAME.INTERACTIVE_SEG);
  return (
    <div className="flex flex-col items-center gap-y-10 w-full">
      <div className="h1">
          Engine for ground truth
      </div>
      <p className="text-lg text-center">
          We care about the success of your AI. Use Graviton annotation tools to define the training input for your AI.
      </p>
        <div className='flex flex-col md:flex-row justify-between' style={{gap: 32}}>
            <div className="feature-grid-control">
                {Object.entries(FEATURE_BUTTON_ICONS).map(([key, icon]) => {
                    return (
                        <FeatButton
                            key={key}
                            selected={selectedFeat === key}
                            onClick={() => {
                                setSelectedFeat(key as FEATURE_NAME)
                            }}
                            icon={icon}
                        />
                    )
                })}
            </div>
            {Object.keys(FEATURE_CONTENTS_OBJ).map((key) => {
                return (
                    <FeatureContent
                        {...FEATURE_CONTENTS_OBJ[key as FEATURE_NAME]}
                        display={key === selectedFeat}
                    />
                )
            })}
        </div>
    </div>
  )
}

export default FeaturesDemo;
