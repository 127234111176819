import './style.scss';

export interface CardInterface {
	title: string;
	img: string;
}

export default function Card(props: CardInterface) {
	const {title, img} = props;
	return (
		<div className='platform-dataset-card'>
			<img className='platform-img-card' src={img} />
			<div className='text-block'>
				<p className='text-base font-medium'>{title}</p>
			</div>
		</div>
	)
}
