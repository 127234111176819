import classNames from 'classnames';
import React from 'react';
import './style.scss';


type Props = {
	title: string;
	content: string;
	imgUrl: string;
}

const WrapperClasses = classNames(
	"flex flex-col items-center p-5 sm:p-10 md:p-15",
	"w-full",
	"platform-box",
	"rounded",
	"self-stretch"
)


const FeatureBox: React.FC<Props> = ({
										 title,
										 content,
										 imgUrl,
									 }) => {
	return (
		<div className={WrapperClasses}>
			{/* Content */}
			<div
				className="max-w-xl md:max-w-full md:w-full mx-auto md:col-span-4 lg:col-span-4"
				data-aos="fade-right"
			>
				<div>
					<h4 className="h4 mb-2 break-words text-center">{title}</h4>
					<p className="text-base text-gray-800 text-start">
						{content}
					</p>
				</div>
			</div>
			{/* Image */}
			<div
				className="relative max-w-2xl md:max-w-full md:w-full mx-auto md:col-span-8 lg:col-span-8 mb-8 md:mb-0 md:order-1"
				data-aos="fade-up"
			>
				<div className="relative">
					<img
						alt="We provide pixelwise annotation services for all types and formats of images, making it easier for computer vision software to recognize objects of a variety of shapes and sizes"
						className="max-w-full mx-auto md:max-w-full h-auto"
						src={imgUrl}
						width="700"
						height="405"
					/>
				</div>
			</div>
		</div>
	)
}

type RowProps = {
	data: Props[]
}
export const FeatureBoxesRow: React.FC<RowProps> = ({data}) => {
	return (
		<div className="flex flex-col md:flex-row items-center gap-5 lg:gap-10">
			{data.map((boxProp, index) => {
				return (
					<FeatureBox key={index.toString()}
								{...boxProp}
					/>
				)
			})}
		</div>
	)
}

export default FeatureBox;
