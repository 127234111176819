import React from "react";
import "./style.scss";

export interface DataWorkflowProps {
	headline: string,
	title: string,
	paragraph: string,
	actionText: string,
	imgSrc: string,
}

export default function DataWorkflow(props: DataWorkflowProps) {
	const {headline, title, paragraph, actionText, imgSrc} = props;

	return (
		<div className={"block industry---grey-rounded-content"}>
			<div className={"case-study---grid-col-2"}>
				<div className="grid-col-2">
					<div className={"small-blue-text"}>{headline}</div>
					<h3 className={"case-study---h3"}>{title}</h3>
					<p className={"case-study---paragraph"}>{paragraph}</p>
					<a className="blue-button inline-block"
					   href="/contact">
						<div className="flex">
							<div>{actionText}</div>
							<div className="ml-2">&rarr;</div>
						</div>
					</a>
				</div>
				<div className="justify-self-center">
					<div className="use-case---hero-video">
						<img className="inline-block" src={imgSrc}
							 height={"100%"} width={"100%"}/>
					</div>
				</div>
			</div>
		</div>
	);
}
