import './style.scss';

export interface CompaniesInterface {
	headline?: string;
}

const companies = [
	{
		logo: require("../../images/ST.png").default,
		name: 'ST Engineering',
		height: 35,
	},
	{
		logo: require("../../images/HiCura.png").default,
		name: 'Hicura',
		height: 50,
	},
	{
		logo: require("../../images/citibeats.png").default,
		name: 'CitiBeats'
	},
	{
		logo: require("../../images/Evolabs.png").default,
		name: 'Evolabs',
		height: 50
	},
	{
		logo: require("../../images/ojamu.png").default,
		name: 'Ojamu',
		height: 36
	},
	{
		logo: require("../../images/moovita.png").default,
		name: 'Moovita'
	},
]

export default function Companies(props: CompaniesInterface) {
	const {headline} = props;
	const renderCompanies = () => {
		const result: JSX.Element[] = [];
		companies.forEach((_c) => {
			result.push(<img style={{height: _c.height ? _c.height : 50}} key={_c.name} src={_c.logo} className='logo'/>)
		})

		return result;
	}

	return (
		<div className='companies'>
			<div className='max-w-3xl mx-auto'>
				{headline && (
					<p className='text-center font-semibold text-sm text-gray-500 mx-4 my-6'
					   style={{textTransform: 'uppercase'}}>
						{headline}
					</p>)
				}
			</div>
			<div className='flex flex-row flex-wrap mx-auto logo-row justify-center'>
				{renderCompanies()}
			</div>
		</div>
	)
}
