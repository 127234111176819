import React, { useEffect } from "react";
import DataWorkflow from "../../partials/industriesPage/dataWorkflow/DataWorkflow";
import Header from "../../partials/Header";
import MainPageBanner from "../../partials/mainPage/mainPageBanner/MainPageBanner";
import LeftImage from "../../images/main-page-banner-1.png";
import MidImage from "../../images/industry-manu-tool.png";
import RightImage from "../../images/main-page-banner-3.png";
import Companies from "../../partials/companies/Companies";
import DefectDetectionImg from "../../images/defect-detection.png";
import AssetInspectionImg from "../../images/asset-inspection.png";
import HighQualityImg from '../../images/high-quality-manu.png';
import ParagraphBlock from "../../partials/paragraphBlock/ParagraphBlock";
import MultipleIndustryCards from "../../partials/industryCard/MultipleIndustryCards";
import WorkerPPEImg from "../../images/worker-ppe.png";
import ToolDemo from "../../partials/toolDemo/ToolDemo";
import MainPageNavListing from "../../partials/mainPage/mainPageNavListing/MainPageNavListing";
import ContactUs from "../../partials/contactus/ContactUs";
import Footer from "../../partials/Footer";
import Faqs from "../../partials/Faqs";
import Steps from "../Platform/AnnotationServices/Steps";
import {Helmet} from "react-helmet";

const industries = [
  {
    title: "Defect Detection",
    imgSrc: DefectDetectionImg,
    linkUrl: "#"
  },
  {
    title: "Asset Inspection",
    imgSrc: AssetInspectionImg,
    linkUrl: "#"
  },
  {
    title: "Worker PPE Detection",
    imgSrc: WorkerPPEImg,
    linkUrl: "#"
  }
];

function Manufacturing() {
  useEffect(() => {
    document.title = "The Data-Centric Platform for Manufacturing AI";
  });

  return (
    <div className={"flex flex-col min-h-screen overflow-hidden"}>
        <Helmet>
            <title>Linh - Annotation services for manufacturing</title>
            <meta name="description" content="We use the best-in-class annotation tools to produce the best quality annotated computer vision data for enterprises" />
        </Helmet>
      <Header dark={false} />

      {/*  Page content */}
      <div className="bg-main">
        <div className="container mx-auto home-container">
          <MainPageBanner
            headline="manufacturing"
            title="The Data-Centric Platform for Manufacturing AI"
            subtitle="Providing high-quality training data for manufacturing AI
            including defect detection, asset inspection and more"
            imgSrcs={[LeftImage, MidImage, RightImage]}
          />
        </div>
        <Companies headline="Empowering the machine learning team at" />
      </div>

      <div className="container mx-auto function-container">
        <ParagraphBlock headline="Use cases"
                        title="Discover more Manufacturing AI use cases"
                        headingLevel="h2"
                        subtitle="Talk to our team about solving your data problems in any use cases" />
        <MultipleIndustryCards industries={industries} />
      </div>
      <div className="container mx-auto function-container mt-4">
        <DataWorkflow
          headline="Data Processes"
          title="Create high-quality dataset for Manufacturing AI"
          paragraph="Generate high-quality ground truth datasets 6x faster. Curate and manage
                      your datasets from one place. Get a dedicated project manager to
                      complete your project and export datasets in your desired format."
          actionText="Get Started"
          imgSrc={HighQualityImg}
        />
      </div>
      <div className="container mx-auto function-container">
        <ToolDemo
          headline="A faster way to get better data"
          title="Every Annotation Tool You Need"
          subtitle="Your team will be up and running in minutes, with easy
          labeling for Image, Video, and Text data."
        />
      </div>
        <div className="container mx-auto function-container">
            <ParagraphBlock headline="Annotation outsourcing"
                            title="Generate high-quality data at fast speed"
                            headingLevel="h2"
                            subtitle="Contact us today and we will take care of your model inputs"/>
        </div>
        <div className='mb-16'>
            <Steps/>
        </div>
      <div className="container mx-auto function-container">
        <MainPageNavListing />
      </div>

      <Faqs />

      <ContactUs />
      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default Manufacturing;
